import React, { Component } from 'react';
import compose from 'recompose/compose';
import {SelectInput, required, translate} from 'react-admin';


class FinanceTypeInputs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            description: [
                { id: '东方电子_提现', name: '东方电子_提现' },
                { id: 'PingPong', name: 'PingPong' },
                { id: '借款', name: '借款' },
                { id: '厂家退款', name: '厂家退款' },
                { id: '财务转账', name: '财务转账' },
                { id: '易宝支付', name: '易宝支付' },
                { id: '上海银行特约', name: '上海银行特约' },
                { id: 'Payoneer', name: 'Payoneer' },
                { id: '其他', name: '其他' },
            ]
        }
    }

    componentDidMount() {
        
    }

    changeType(e) {

        if(e[0] === "i") {
          if(this !== undefined) {
            this.setState({
              description: [
                { id: '东方电子_提现', name: '东方电子_提现' },
                { id: 'PingPong', name: 'PingPong' },
                { id: '借款', name: '借款' },
                { id: '厂家退款', name: '厂家退款' },
                { id: '财务转账', name: '财务转账' },
                { id: '易宝支付', name: '易宝支付' },
                { id: '上海银行特约', name: '上海银行特约' },
                { id: 'Payoneer', name: 'Payoneer' },
                { id: '其他', name: '其他' },
              ]
            })
          }
        } else if(e[0] === "o") {
          if(this !== undefined) {
            this.setState({
              description: [
                { id: '转给海勇', name: '转给海勇' },
                { id: '采购货款', name: '采购货款' },
                { id: '办公租金', name: '办公租金' },
                { id: '工资', name: '工资' },
                { id: '差旅费', name: '差旅费' },
                { id: '培训费', name: '培训费' },
                { id: '办公耗材', name: '办公耗材' },
                { id: '运费', name: '运费' },
                { id: '市场部', name: '市场部' },
                { id: '客服部', name: '客服部' },
                { id: '奉献', name: '奉献' },
                { id: '还款', name: '还款' },
                { id: '利息', name: '利息' },
                { id: '其他', name: '其他' },
              ]
            })     
          }

        }

    }

    render() {
        return (
            <span>
                <SelectInput source="type" choices={[
                    { id: 'income', name: '进账（收入）' },
                    { id: 'outcome', name: '出账（支出）' },
                ]} validate={required()} onChange={this.changeType.bind(this)} style={{ width: "256px" }} label="resources.finances.fields.type" />

                <br />

                <SelectInput source="description" choices={this.state.description} validate={required()} style={{ width: "256px" }} label="resources.finances.fields.description" />

            </span>
        );
    }
}

const enhance = compose(
    translate
);

export default enhance(FinanceTypeInputs);