import React from 'react';
import Typography from '@material-ui/core/Typography';

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Products</Typography>
        <Typography variant="body1">
            
        </Typography>
    </div>
);

export default Aside;
