var change_field = "";

const autoUpdateBatchQty = (qty, boxes, qtyInBox, qtyInTrailBox) => (event) => {

  if(event.target){
    change_field = event.target.id;
  }

  if(change_field === "boxes_start" || change_field === "boxes_end") {
    if(event.boxes_start && event.boxes_end) {
        if(event.boxes_end === boxes && qtyInTrailBox > 0) {
            event.qtyBatch = (event.boxes_end - event.boxes_start) * qtyInBox + qtyInTrailBox
        } else {
            event.qtyBatch = (event.boxes_end - event.boxes_start + 1) * qtyInBox
        }
    }
  }


}

export default autoUpdateBatchQty;