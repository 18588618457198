/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    NumberInput,
    SelectInput,
    DateInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';
import RequestedAside from './RequestedAside';
import withStyles from '@material-ui/core/styles/withStyles';
import autoUpdateBatchQty from './autoUpdateBatchQty';

const StockInRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);

const styles = {
    boxes_start: { display: 'inline-block' },
    boxes_end: { display: 'inline-block', marginLeft: 32 },
    flex: { display: 'flex' },
};

const StockInRequestCreate = function({ classes,permissions, ...props }) {
 const urlParams = new URLSearchParams(props.location.search);
 const purchaseId = urlParams.get('purchase_id') ? parseInt(urlParams.get('purchase_id')) : '';
 const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
 const pm = urlParams.get('pm') ? parseInt(urlParams.get('pm')) : JSON.parse(localStorage.getItem('lbtoken')).value.userId;
 const qty = urlParams.get('qty') ? parseInt(urlParams.get('qty')) : '';
 const boxes = urlParams.get('boxes') ? parseInt(urlParams.get('boxes')) : '';
 const qtyInBox = urlParams.get('qtyInBox') ? parseInt(urlParams.get('qtyInBox')) : '';
 const qtyInTrailBox = urlParams.get('qtyInTrailBox') ? parseInt(urlParams.get('qtyInTrailBox')) : '';

 return (
    <Create aside={<RequestedAside purchaseId={purchaseId} productId={productId}/>} {...props}>
        <TabbedForm toolbar={<StockInRequestEditToolbar permissions={permissions} />} onChange={autoUpdateBatchQty(qty, boxes, qtyInBox, qtyInTrailBox)}>
            <FormTab label="resources.stockinrequests.form.summary" path="">
                {purchaseId &&
                <ReferenceInput 
                    source="purchaseId" 
                    reference="purchases" 
                    perPage={100} 
                    label="resources.stockinrequests.fields.purchase" 
                    validate={required()} 
                    defaultValue={purchaseId}
                    disabled
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                {productId &&
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.stockinrequests.fields.product" 
                    validate={required()} 
                    defaultValue={productId}
                    disabled
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }
                
                <NumberInput
                    source="boxes"
                    validate={required()}
                    disabled
                />
                <NumberInput
                    source="boxes_start"
                    validate={required()}
                    formClassName={classes.boxes_start}
                />
                <NumberInput
                    source="boxes_end"
                    validate={required()}
                    formClassName={classes.boxes_end}
                />
                <NumberInput
                    source="qtyBatch"
                    validate={required()}
                    label="resources.stockinrequests.record.batch_qty" 
                />
                <ArrayInput source="waybills">
                    <SimpleFormIterator>
                        <TextInput
                            source="waybill"
                            validate={required()}
                            label="resources.stockinrequests.fields.waybill" 
                        />
                        <SelectInput source="shippingCompany" choices={[
                            { id: 'DHL', name: 'DHL' },
                            { id: 'Fedex', name: 'Fedex' },
                            { id: 'UPS', name: 'UPS' },
                            { id: 'USPS', name: 'USPS' },
                        ]} defaultValue="DHL" validate={required()}
                        label="resources.stockinrequests.fields.shippingCompany"  
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <DateInput
                    source="estArrivalDate"
                    label="resources.stockinrequests.fields.estArrivalDate"
                    validate={required()}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'stl', name: 'STL' },
                ]} defaultValue="stl" validate={required()} />

                <SelectInput source="status" choices={[
                    { id: 'stocking_in', name: 'resources.stockinrequests.tab.stocking_in' },
                    { id: 'stocked_in', name: 'resources.stockinrequests.tab.stocked_in' },
                    { id: 'cancelled', name: 'resources.stockinrequests.tab.cancelled' },
                ]} defaultValue="stocking_in" validate={required()} />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" validate={required()} defaultValue={pm}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

            </FormTab>

        </TabbedForm>
    </Create>
)
};

export default withStyles(styles)(StockInRequestCreate);
