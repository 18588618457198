/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';

import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    SimpleList,
    TextField,
    ReferenceField,
    DateField,
    CardActions,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'packagestockins-'+n)
}

const PackageStockInActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const PackageStockInFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="packageId" reference="packages" label="resources.packagestockins.fields.package" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.packagestockins.fields.user" sort={{ field: 'username', order: 'ASC' }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const PurchaseReferenceField = props => (
    <div>
        {props.record.purchaseId ?
            <ReferenceField
                source="purchaseId"
                reference="purchases"
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : ""
        }
    </div>
);

const PackageStockInList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<PackageStockInFilter permissions={permissions} />}
        sort={{ field: 'stockInDate', order: 'DESC' }}
        actions={<PackageStockInActions />}
        exporter={exporter}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        permissions === 'admin' ? record.role : null
                    }
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <ReferenceField source="packageId" reference="packages" label="resources.packagestockins.fields.package">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="qty" />
                    <DateField source="stockInDate" options={{timeZone: "UTC"}}/>
                    <ReferenceField source="appUserId" reference="appusers" label="resources.packagestockins.fields.user">
                        <TextField source="username" />
                    </ReferenceField>
                    <PurchaseReferenceField label="resources.packagestockins.fields.purchase" />

                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default PackageStockInList;
