/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockInBoxButton extends Component {
     
    handleClick = (event) => {
        event.stopPropagation();
        const { record, box } = this.props;
        let location = '#/stockins/create?request_id='+record.id+'&product_id='+record.productId+'&qty='+record.qtyInBox+'&qtyInBox='+record.qtyInBox+'&qtyInTrailBox='+record.qtyInTrailBox+'&startbox='+record.boxes_start+'&endbox='+record.boxes_end;
        if(box) {
            location += '&box='+box
        }
        window.location = location  
    }

    render() {
        return (
            <Button label="stock_in" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.stockinrequests.actions.stock_in')}</Button>
        );
    }
}

StockInBoxButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInBoxButton);