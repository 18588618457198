import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    WithPermissions,
} from 'react-admin';

import products from '../products';
import packages from '../packages';
import purchases from '../purchases';
import payments from '../payments';
import packagestockins from '../package_stock_ins';
import packagestockouts from '../package_stock_outs';
import stockinrequests from '../stock_in_requests';
import stockins from '../stock_ins';
import stockoutrequests from '../stock_out_requests';
import stockouts from '../stock_outs';
import users from '../users';
import accounts from '../accounts';
import finances from '../finances';

const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";

class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, logout, translate } = this.props;

        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />

                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'PB' || permissions === 'WM'
                        ?
                        <MenuItemLink
                            to={`/products`}
                            primaryText={translate(`resources.products.menu`, {
                                smart_count: 2,
                            })}
                            leftIcon={<products.icon />}
                            onClick={onMenuClick}
                        />
                        : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'PB' || permissions === 'WM'
                        ?
                        <MenuItemLink
                            to={`/packages`}
                            primaryText={translate(`resources.packages.menu`, {
                                smart_count: 2,
                            })}
                            leftIcon={<packages.icon />}
                            onClick={onMenuClick}
                        />
                        : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'WM' || permissions === 'PB'
                            ? <MenuItemLink
                                to={`/packagestockins`}
                                primaryText={translate(`resources.packagestockins.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<packagestockins.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'WM' || permissions === 'PB'
                            ? <MenuItemLink
                                to={`/packagestockouts`}
                                primaryText={translate(`resources.packagestockouts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<packagestockouts.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'PB'
                            ? <MenuItemLink
                                to={`/purchases`}
                                primaryText={translate(`resources.purchases.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<purchases.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'PB' || permissions === 'FM'
                            ? <MenuItemLink
                                to={`/payments`}
                                primaryText={translate(`resources.payments.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<payments.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'PB' || permissions === 'WM' || permissions === 'FM'
                            ? <MenuItemLink
                                to={`/stockinrequests`}
                                primaryText={translate(`resources.stockinrequests.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockinrequests.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'WM' || permissions === 'FM'
                            ? <MenuItemLink
                                to={`/stockins`}
                                primaryText={translate(`resources.stockins.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockins.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM' || permissions === 'WM' || permissions === 'FM'
                            ? <MenuItemLink
                                to={`/stockoutrequests`}
                                primaryText={translate(`resources.stockoutrequests.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockoutrequests.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'WM' || permissions === 'Admin' || permissions === 'FM'
                            ? <MenuItemLink
                                to={`/stockouts`}
                                primaryText={translate(`resources.stockouts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<stockouts.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || userId === 26 || userId === 32
                            ? <MenuItemLink
                                to={`/finances`}
                                primaryText={translate(`resources.finances.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<finances.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin' || permissions === 'PM'
                            ? <MenuItemLink
                                to={`/accounts`}
                                primaryText={translate(`resources.accounts.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<accounts.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        permissions === 'Admin'
                            ? <MenuItemLink
                                to={`/appusers`}
                                primaryText={translate(`resources.appusers.menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<users.icon />}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />

                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('pos.configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);