/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    DateInput,
    DisabledInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';

import { dateFormatter, dateParser } from '../lib/date';

const StockOutEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);


const StockOutEdit = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);

    return (
    <Edit {...props}>
        <TabbedForm toolbar={<StockOutEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockouts.form.summary" path="">
                
                <TextInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                <DateInput
                    source="stockOutDate"
                    label="resources.stockouts.fields.stockOutDate"
                    format={dateFormatter} parse={dateParser}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'stl', name: 'STL' },
                ]} validate={required()} />

                <ReferenceInput source="productId" reference="products" label="resources.stockouts.fields.product" perPage={100} validate={required()} defaultValue={parseInt(urlParams.get('product_id'))}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="stockOutRequestId" reference="stockoutrequests" label="resources.stockouts.fields.stock_out_request" validate={required()} defaultValue={parseInt(urlParams.get('request_id'))}>
                    <DisabledInput source="id" />
                </ReferenceInput>
                <ReferenceInput source="appUserId" reference="appusers" validate={required()} label="resources.stockouts.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Edit>
    )
};

export default StockOutEdit;
