import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import {
    EditButton,
    DateField,
    translate,
    TextField,
} from 'react-admin';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const PaymentIdField = ({ record }) => (
    <span><a href={`/#/payments/${record.id}`} style={{textDecoration: "none"}}>{record.purchaseId ? 'OR' + record.purchaseId.toString().padStart(4, '0') + 'P' + record.count : ''}</a></span>
);

const PercentageField = ({ record }) => (
    <span>{record.percentage * 100}%</span>
)

const AmountField = ({ record }) => (
    <span>¥{new Intl.NumberFormat().format(record.amount_cny)} (${new Intl.NumberFormat().format(record.amount)})</span>
);

const PaymentTypeField = ({ record }) => (
    <span>
    {
    record.payment_type === "full_payment" ?
    "Full Payment(全款)" :
    record.payment_type === "deposit_payment" ?
    "Deposit Payment(首款)" :
    record.payment_type === "balance_payment" ?
    "Balance Payment(尾款)" : ""
    }
    </span>
)

const MobileGrid = ({ classes, ids, data, basePath, translate, permissions, userId }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                {translate('resources.payments.fields.id', 1)}:&nbsp;
                                <PaymentIdField label="resources.payments.fields.id" record={data[id]}/>
                            </span>
  
                            <EditButton label="pos.view" basePath={basePath} record={data[id]}/>
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.payment_date')}:&nbsp;
                        <DateField source="payment_date" options={{timeZone: "UTC"}} record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.status')}:&nbsp;
                        <TextField source="status" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.percentage')}:&nbsp;
                        <PercentageField label="resources.payments.fields.percentage" record={data[id]}/> 
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.amount')}:&nbsp;
                        <AmountField label="resources.payments.fields.amount" source="amount" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.payment_type')}:&nbsp;
                        <PaymentTypeField label="resources.payments.fields.payment_type" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.payments.fields.note')}:&nbsp;
                        <TextField source="note" record={data[id]}/>
                    </span>
                    
                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);