/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, ImageField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved

import ProductTitle from './ProductTitle';

const ProductShow = ({ permissions, ...props }) => (
    <Show title={<ProductTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="boxmark_name" />
            <TextField source="brand" />
            <TextField source="supplier_name" />
            <TextField source="supplier_link" />
            <TextField source="shelf" />
            <ImageField source="image" />
            <TextField source="qty" />
            <TextField source="qtyInBox" />
            <TextField source="box_weight" />
            <TextField source="box_size" />
            <TextField source="asin" />
            <TextField source="country" />
            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);

ProductShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default ProductShow;
