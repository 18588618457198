import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadBoxLabelField extends Component {

	handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { record } = this.props;
        window.open(record.boxLabel.replace("s3.amazonaws.com", "s3.us-east-1.amazonaws.com"), '_blank');    
    }

    getBoxLabelFile = () => {
        const { record } = this.props;
        if(typeof record.boxLabel === 'string') {
            return record.boxLabel.substr(record.boxLabel.lastIndexOf('.') + 1)
        } else {
            return "";
        }
    }

    render() {
	    return (
	    	this.props.record.boxLabel === null ? "" : (typeof this.props.record.boxLabel === "string") ? <a href={this.props.record.boxLabel.replace("s3.amazonaws.com", "s3.us-east-1.amazonaws.com")} label="download_boxbabel" onClick={this.handleClick} color="primary" variant="raised">{this.getBoxLabelFile()}</a> : "Uploading"
	    )
	}
};

DownloadBoxLabelField.defaultProps = {
    source: 'boxLabel',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadBoxLabelField);