import React, { Component }  from 'react';
import {showNotification, UPDATE, withDataProvider} from 'react-admin';

class FbaIdEditableField extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isEditing : false,
            fba_id: this.props.record.fba_id
        }
    }

    setFbaId = (value) => {
        this.setState({
            fba_id: value
        })
    }

    setEditing = (isEditing) => {
        this.setState({
            isEditing: isEditing
        })
    }

    updateFbaId = (e) => {

        this.setState({
            isEditing: false
        })

        if(this.props.record.fba_id !== this.state.fba_id) {
           const { dataProvider, dispatch, record } = this.props;
           const updatedRecord = { ...record, fba_id: this.state.fba_id }; 
           dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
            .then(() => {
                dispatch(showNotification('resources.stockoutrequests.actions.fba_id_updated'));
            })
            .catch((e) => {
                dispatch(showNotification('resources.stockoutrequests.actions.fba_id_updated_error', 'warning'));
            });
        }

    }

    keyDownUpdateFbaId = (e) => {
        if (e.key === 'Enter') {
            this.setState({
                isEditing: false
            })

           const { dataProvider, dispatch, record } = this.props;
           const updatedRecord = { ...record, fba_id: this.state.fba_id }; 
           dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
            .then(() => {
                dispatch(showNotification('resources.stockoutrequests.actions.fba_id_updated'));
            })
            .catch((e) => {
                dispatch(showNotification('resources.stockoutrequests.actions.fba_id_updated_error', 'warning'));
            });
        }
    }

    render() {
        return (
            <div>{this.state.isEditing ? (
                <div
                onBlur={e => this.updateFbaId(e)}
                >
                  <input type="text" name="fba_id" placeholder="FBA ID" value={this.state.fba_id}
                    onChange={e => this.setFbaId(e.target.value)}
                    onKeyDown={e => this.keyDownUpdateFbaId(e)}
                  />
                </div>
              ) : (
                <div
                  onClick={() => this.setEditing(true)}
                >
                  <span>
                    {this.state.fba_id || "FBA ID"}
                  </span>
                </div>
              )}
            </div>
        )
    }
};

FbaIdEditableField.defaultProps = {
    source: 'fba_id',
    addLabel: true,
};

export default withDataProvider(FbaIdEditableField);