import React from 'react';

const CurrentInnerPageField = function({ record = {} }) { 
	
	const filename = record.innerPage ? record.innerPage.substr(record.innerPage.lastIndexOf('/') + 1) : '';

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <a href={record.innerPage} target="_blank" rel="noopener noreferrer">{filename}</a>
    </div>
    )
};

CurrentInnerPageField.defaultProps = {
    source: 'innerPage',
    addLabel: true,
};

export default CurrentInnerPageField;