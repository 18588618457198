import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class UploadContractButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/purchases/'+record.id;  
    }

    render() {
        return (
        <div>
            <Button label="uploadcontract" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.purchases.actions.uploadcontract')}</Button>
        </div>
        );
    }
}

UploadContractButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(UploadContractButton);