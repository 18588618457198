/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    LongTextInput,
    ImageInput,
    ImageField,
    Toolbar,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Aside from './Aside';

const ProductCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const styles = {
    name: { float: 'left' },
    boxmark_name: { float: 'left', marginLeft: 32 },
    supplier_name: { float: 'left', clear: 'left' },
    supplier_link: { float: 'left', marginLeft: 32 },
    brand: { float: 'left', clear: 'left'},
    country: { float: 'left', marginLeft: 32 },
    shelf: { float: 'left', clear: 'left'},
    qty_stl: { float: 'left', marginLeft: 32 },
    unit_weight: { float: 'left', clear: 'left' },
    qtyInBox: { float: 'left', marginLeft: 32 },
    box_weight: { float: 'left', clear: 'left' },
    box_size: { float: 'left', marginLeft: 32 },
};

const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";


const ProductCreate = ({ classes, permissions, ...props }) => (
    <Create {...props} aside={<Aside />}>
        <TabbedForm toolbar={<ProductCreateToolbar permissions={permissions} />}>
            <FormTab label="resources.products.form.summary" path="">
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                    formClassName={classes.name}
                />
                <TextInput
                    source="boxmark_name"
                    defaultValue=""
                    validate={required()}
                    formClassName={classes.boxmark_name}
                />
                <TextInput
                    source="supplier_name"
                    defaultValue=""
                    formClassName={classes.supplier_name}
                    validate={required()}
                />
                <TextInput
                    source="supplier_link"
                    defaultValue=""
                    formClassName={classes.supplier_link}
                    validate={required()}
                />
                <TextInput
                    source="brand"
                    formClassName={classes.brand}
                    defaultValue=""
                />
                <SelectInput source="country" allowEmpty emptyValue="" 
                    formClassName={classes.country}
                    choices={[
                        { id: 'US', name: 'US' },
                        { id: 'UK', name: 'UK' },
                        { id: 'CA', name: 'CA' },
                        { id: 'DE', name: 'DE' },
                    ]} 
                />
                
                { userId === 10 && 
                <div>
                    <TextInput
                        source="shelf"
                        defaultValue=""
                        formClassName={classes.shelf}
                        label="resources.products.fields.shelf"
                    />
                    <NumberInput
                        source="qty"
                        defaultValue=""
                        formClassName={classes.qty_stl}
                        label="resources.products.fields.qty"
                    />
                    
                </div>
                }
                <NumberInput
                    source="unit_weight"
                    validate={required()}
                    formClassName={classes.unit_weight}
                    label="resources.products.fields.unit_weight"
                />
                <NumberInput
                    source="qtyInBox"
                    formClassName={classes.qtyInBox}
                    label="resources.products.fields.qtyInBox"
                />
                <NumberInput
                    source="box_weight"
                    formClassName={classes.box_weight}
                    label="resources.products.fields.box_weight"
                />
                <TextInput
                    source="box_size"
                    defaultValue=""
                    formClassName={classes.box_size}
                    label="resources.products.fields.box_size"
                />

                <LongTextInput
                    source="manufacture_info"
                    validate={required()}
                />

                <ImageInput source="image" accept="image/*" label="resources.products.fields.image">
                    <ImageField source="src" title="title" />
                </ImageInput>
                
                <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" perPage={100} filterToQuery={searchText => ({username: {"like": `%${searchText}%`, "options": "i"}})} sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="username" />
                </ReferenceInput>
            </FormTab>

        </TabbedForm>
    </Create>
);

export default withStyles(styles)(ProductCreate);
