import PaymentIcon from '@material-ui/icons/MonetizationOn';
import PaymentCreate from './PaymentCreate';
import PaymentEdit from './PaymentEdit';
import PaymentList from './PaymentList';
import PaymentShow from './PaymentShow';

export default {
    list: PaymentList,
    create: PaymentCreate,
    edit: PaymentEdit,
    show: PaymentShow,
    icon: PaymentIcon,
};
