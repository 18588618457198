import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StockInAllButton from './StockInAllButton';
import StockInBoxButton from './StockInBoxButton';

const Aside = ({ record, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
            <div style={{margin: '0 0 0 1em'}}>
                {record && <ProductInfo record={record} basePath={basePath} translate={translate} />}
            </div>

            <div style={{margin: '1em 0 0 1em'}}>
                {record && <PurchaseInfo record={record} basePath={basePath} translate={translate} />}
            </div>

            <div style={{margin: '1em 0 0 1em'}}>
                {record && <StockInRequestInfo record={record} basePath={basePath} translate={translate} />}
            </div>

            <div style={{margin: '1em 0 0 1em'}}>
                {record && <StockInList record={record} basePath={basePath} translate={translate} />}
            </div>
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const imgStyle = {
  maxHeight: '5rem',
  position: 'relative',
  margin: '0.5rem',
}

const ProductInfo = ({ record, basePath, translate }) => (
    <Query type="GET_ONE" resource="products" payload={{id: record.productId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/products/${data.id}` : "";
            return (
                <Card>
                    <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.image')}</TableCell>
                              <TableCell width="75%"><a href={redirect}><img src={data.image.replace("s3.amazonaws.com", "s3.us-east-1.amazonaws.com")} style={imgStyle} alt={data.name} /></a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.name')}</TableCell>
                              <TableCell width="75%"><a href={redirect}>{data.name}</a> ({data.boxmark_name})</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.qty')}</TableCell>
                              <TableCell width="75%">{data.qty}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.shelf')}</TableCell>
                              <TableCell width="75%">{data.shelf}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)

const PurchaseInfo = ({ record, basePath, translate }) => (
    <Card>
        <CardHeader
              title={translate('resources.purchases.form.summary')}
          />
        <CardContent>
          <Table aria-label="leftbox table">
              <TableHead>
                <TableRow>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qty')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.purchase')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.record.purchase_boxes')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInBox')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInTrailBox')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.weightPerBox')}</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell style={{padding: 0}}>{record.qty}</TableCell>
                  <TableCell style={{padding: 0}}><a href={'/#/purchases/' + record.purchaseId}>OR{record.purchaseId.toString().padStart(4, '0')}</a></TableCell>
                  <TableCell style={{padding: 0}}>{record.boxes}</TableCell>
                  <TableCell style={{padding: 0}}>{record.qtyInBox}</TableCell>
                  <TableCell style={{padding: 0}}>{record.qtyInTrailBox}</TableCell>
                  <TableCell style={{padding: 0}}>{record.weightPerBox}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </CardContent>
    </Card>
);

const StockInRequestInfo = ({ record, basePath, translate }) => (
    <Card>
        <CardHeader
              title={translate('resources.stockinrequests.form.edit')}
          />
        <CardContent>
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.purchase')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.record.batch_boxes')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyBatch')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.waybills')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyStockedIn')}</TableCell>
                  <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyPending')}</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell style={{padding: 0}}><a href={'/#/purchases/' + record.purchaseId}>OR{record.purchaseId.toString().padStart(4, '0')}</a></TableCell>
                  <TableCell style={{padding: 0}}>{record.boxes_end - record.boxes_start + 1} Boxes : {record.boxes_start} - {record.boxes_end}</TableCell>
                  <TableCell style={{padding: 0}}>{record.qtyBatch}</TableCell>
                  <TableCell style={{padding: 0}}><WaybillList waybills={record.waybills} /></TableCell>
                  <TableCell style={{padding: 0}}>{record.qtyStockedIn}</TableCell>
                  <TableCell style={{padding: 0}}>{record.qtyPending}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </CardContent>
    </Card>
);

const WaybillList = ({waybills}) => {
  if(waybills === null || waybills.length === 0) {
    return ""
  }
  const listItems = waybills.map((w) =>
    <li>{ w.waybill + '-' + w.shippingCompany }</li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

const payload = (record) => ({
   pagination: { page: 1, perPage: 7000 },
   sort: { field: 'id', order: 'ASC' },
   filter: { stockInRequestId: record.id }
});

const StockInList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="stockins" payload={payload(record)}>
        {({ data, total, loading, error }) => {
          
          let boxes_left = [];

          if(data !== null) {
            let boxes_arrived = data.map(d => d.box);
            let i = parseInt(record.boxes_start);
            while(i <= parseInt(record.boxes_end)) {
              if(!boxes_arrived.includes(i)) {
                let qty = record.qtyInBox;
                if(i === parseInt(record.boxes)) {
                  if(record.qtyInTrailBox > 0) {
                    qty = record.qtyInTrailBox;
                  }
                }
                boxes_left.push({qty: qty, box: i});
              }
              i++;
            }
          }
          
          
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
              <div>
                <Card>
                      <CardHeader
                          title={translate('resources.stockinrequests.record.stockins_left')}
                      />
                      <CardContent>
                        <StockInAllButton record={record} />
                        <Table aria-label="leftbox table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.box')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockins.desc.qty')}</TableCell>
                                <TableCell style={{padding: 0}}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {boxes_left.map((row) => (
                                <TableRow key={row}>
                                  <TableCell style={{padding: 0}}>{row.box}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                                  <TableCell style={{padding: 0}}><StockInBoxButton record={record} box={row.box} /></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            
                          </Table>
                      </CardContent>
                  </Card>

                  <Card style={{ margin: '1em 0 0 0' }}>
                      <CardHeader
                          title={translate('resources.stockinrequests.record.stockins_list')}
                      />
                      <CardContent>
                        <Table aria-label="stockedinbox table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.box')}</TableCell>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.qty')}</TableCell>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.stockInDate')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell style={{padding: 0}}>{row.box}</TableCell>
                                <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                                <TableCell style={{padding: 0}}>{new Date(row.stockInDate).toISOString().split('T')[0]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          
                        </Table>
                      </CardContent>


                  </Card>

              </div>
            );
        }}
    </Query>
);

const enhance = compose(
    translate
);

export default enhance(Aside);