import React, { Component } from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import loopbackClient from 'react-admin-loopback2';
import addUploadFeature from './addUploadFeature';
import {authProvider} from './authProvider';
import i18nProvider from './i18nProvider';
import Dashboard from './dashboard';
import customRoutes from './customRoutes';
import { Layout } from './layout';
import { Login } from './auth';
import users from './users';
import products from './products';
import packages from './packages';
import purchases from './purchases';
import payments from './payments';
import packagestockins from './package_stock_ins';
import packagestockouts from './package_stock_outs';
import stockinrequests from './stock_in_requests';
import stockins from './stock_ins';
import stockoutrequests from './stock_out_requests';
import stockouts from './stock_outs';
import accounts from './accounts';
import finances from './finances';
import {loobackApiUrl} from './config';

const dataProvider = loopbackClient(loobackApiUrl);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

class App extends Component {
  render() {
    return (
      <div>
        <Admin 
        title="PL Warehouse"
        loginPage={Login}
        appLayout={Layout}
        dashboard={Dashboard} 
        customRoutes={customRoutes}
        dataProvider={uploadCapableDataProvider} 
        authProvider={authProvider(loobackApiUrl + '/AppUsers/login')}
        locale="cn"
        i18nProvider={i18nProvider}
        >
          {permissions => [
            <Resource name="appusers" {...users} />,
          	<Resource name="products" {...products} />,
            <Resource name="packages" {...packages} />,
        	  <Resource name="purchases" {...purchases} />,
            <Resource name="payments" {...payments} />,
            <Resource name="packagestockins" {...packagestockins} />,
            <Resource name="packagestockouts" {...packagestockouts} />,
            <Resource name="stockinrequests" {...stockinrequests} />,
            <Resource name="stockins" {...stockins} />,
            <Resource name="stockoutrequests" {...stockoutrequests} />,
            <Resource name="stockouts" {...stockouts} />,
            <Resource name="finances" {...finances} />,
            <Resource name="accounts" {...accounts} />
          ]}
        </Admin>
      </div>
    );
  }
}

export default App;
