import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Aside = ({ request_id, startbox, endbox, qtyInBox, qtyInTrailBox, boxes, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
                {request_id && <StockInList request_id={request_id} startbox={startbox} endbox={endbox} qtyInBox={qtyInBox} qtyInTrailBox={qtyInTrailBox} boxes={boxes} basePath={basePath} translate={translate} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const payload = (request_id) => ({
   pagination: { page: 1, perPage: 7000 },
   sort: { field: 'id', order: 'ASC' },
   filter: { stockInRequestId: request_id }
});

const StockInList = ({ request_id, startbox, endbox, qtyInBox, qtyInTrailBox, boxes, basePath, translate }) => (

    <Query type="GET_LIST" resource="stockins" payload={payload(request_id)}>
        {({ data, total, loading, error }) => {
          
          let boxes_left = [];

          if(data !== null) {
            let boxes_arrived = data.map(d => d.box);
            let i = parseInt(startbox);
            while(i <= parseInt(endbox)) {
              if(!boxes_arrived.includes(i)) {
                let qty = qtyInBox;
                if(i === parseInt(boxes)) {
                  qty = qtyInTrailBox;
                }
                boxes_left.push({qty: qty, box: i});
              }
              i++;
            }
            console.log(boxes_left);
          }
          
          
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
              <div>
                <Card>
                      <CardHeader
                          title={translate('resources.stockinrequests.record.stockins_left')}
                      />
                      <CardContent>
                        <Table aria-label="leftbox table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.box')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockins.desc.qty')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {boxes_left.map((row) => (
                                <TableRow key={row}>
                                  <TableCell style={{padding: 0}}>{row.box}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            
                          </Table>
                      </CardContent>
                  </Card>

                  <Card style={{ margin: '1em 0 0 0' }}>
                      <CardHeader
                          title={translate('resources.stockinrequests.record.stockins_list')}
                      />
                      <CardContent>
                        <Table aria-label="stockedinbox table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.box')}</TableCell>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.qty')}</TableCell>
                              <TableCell style={{padding: 0}}>{translate('resources.stockins.fields.stockInDate')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell style={{padding: 0}}>{row.box}</TableCell>
                                <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                                <TableCell style={{padding: 0}}>{new Date(row.stockInDate).toISOString().split('T')[0]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          
                        </Table>
                      </CardContent>


                  </Card>

              </div>
            );
        }}
    </Query>
);

const enhance = compose(
    translate
);

export default enhance(Aside);