import React, { Component }  from 'react';
import {showNotification, UPDATE, withDataProvider} from 'react-admin';

class TaskHandlingTimeEditableField extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isEditing : false,
            estimated_task_time: this.props.record.estimated_task_time
        }
    }

    setEstimatedTaskTime = (value) => {
        this.setState({
            estimated_task_time: value
        })
    }

    setEditing = (isEditing) => {
        this.setState({
            isEditing: isEditing
        })
    }

    updateEstimatedTaskTime = (e) => {

        this.setState({
            isEditing: false
        })

        if(this.props.record.estimated_task_time !== this.state.estimated_task_time) {
           const { dataProvider, dispatch, record } = this.props;
           const updatedRecord = { ...record, estimated_task_time: this.state.estimated_task_time }; 
           dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
            .then(() => {
                dispatch(showNotification('resources.stockoutrequests.actions.estimated_task_time_updated'));
            })
            .catch((e) => {
                dispatch(showNotification('resources.stockoutrequests.actions.estimated_task_time_updated_error', 'warning'));
            });
        }

    }

    keyDownUpdateEstimatedTaskTime = (e) => {
        if (e.key === 'Enter') {
            this.setState({
                isEditing: false
            })

           const { dataProvider, dispatch, record } = this.props;
           const updatedRecord = { ...record, estimated_task_time: this.state.estimated_task_time }; 
           dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
            .then(() => {
                dispatch(showNotification('resources.stockoutrequests.actions.estimated_task_time_updated'));
            })
            .catch((e) => {
                dispatch(showNotification('resources.stockoutrequests.actions.estimated_task_time_updated_error', 'warning'));
            });
        }
    }

    render() {
        return (
            <div>{this.state.isEditing ? (
                <div
                onBlur={e => this.updateEstimatedTaskTime(e)}
                >
                  <input type="text" name="estimated_task_time" placeholder="Handling Time" value={this.state.estimated_task_time}
                    onChange={e => this.setEstimatedTaskTime(e.target.value)}
                    onKeyDown={e => this.keyDownUpdateEstimatedTaskTime(e)}
                  />
                </div>
              ) : (
                <div
                  onClick={() => this.setEditing(true)}
                >
                  <span>
                    {this.state.estimated_task_time || "Handling Time"}
                  </span>
                </div>
              )}
            </div>
        )
    }
};

TaskHandlingTimeEditableField.defaultProps = {
    source: 'estimated_task_time',
    addLabel: true,
};

export default withDataProvider(TaskHandlingTimeEditableField);