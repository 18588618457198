/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SaveButton,
    Toolbar,
    FormTab,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    TabbedForm,
    TextInput,
    DateInput,
    FileInput,
    FileField,
    LongTextInput,
    required,
} from 'react-admin';

import Aside from './Aside';
import CurrentContractField from './CurrentContractField';
import CurrentBarcodeField from './CurrentBarcodeField';
import CurrentTransparencyField from './CurrentTransparencyField';
import PriceInputs from './PriceInputs';
import autoUpdatePrice from './autoUpdatePrice';

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}

const ProductField = function({ record }) { 
    return (
        record.productId ? 
                    <ReferenceInput 
                        source="productId" 
                        reference="products" 
                        perPage={100} 
                        label="resources.purchases.fields.product" 
                        validate={required()} 
                    >
                        <SelectInput optionText="name" disabled />
                    </ReferenceInput>
                    : ""
    );
}

const PackageField = function({ record }) { 
    return (
        record.packageId ? 
                <ReferenceInput 
                    source="packageId" 
                    reference="packages" 
                    perPage={100} 
                    label="resources.purchases.fields.package" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" disabled />
                </ReferenceInput>
                : ""
    );
}

const PurchaseToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const PurchaseEdit = function({ permissions, ...props }) {

    const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";
    const role = JSON.parse(localStorage.getItem('lbrole')).value;

    return (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm onChange={autoUpdatePrice} toolbar={<PurchaseToolbar permissions={permissions} />}>
            <FormTab label="resources.purchases.form.summary" path="">

               <TextInput
                    source="id"
                    format={id => id ? 'OR' + id.toString().padStart(5, '0') : ""} 
                    parse={id => id ? Number(id.substring(2)) : ""}
                    disabled
                />
                
                <ProductField />
                <PackageField />

                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />

                <TextInput
                    source="fnsku"
                    defaultValue=""
                />

                <FileInput source="barcode" label="resources.purchases.fields.barcode" multiple>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>

                <CurrentBarcodeField />

                <FileInput source="transparency" label="resources.purchases.fields.transparency" multiple>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
  
                <CurrentTransparencyField />

                <FileInput source="contract" label="resources.purchases.fields.contract" multiple>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
  
                <CurrentContractField />
                
                
                <PriceInputs />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

                <ReferenceInput source="accountId" reference="accounts" label="resources.purchases.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>

                <SelectInput source="transport" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'sea', name: 'resources.purchases.transport.sea' },
                    { id: 'air', name: 'resources.purchases.transport.air' },
                    { id: 'sea_air', name: 'resources.purchases.transport.sea_air' },
                    { id: 'factory', name: 'resources.purchases.transport.factory' },
                ]}
                />

                <DateInput
                    source="production_finish_date"
                    validate={role == "PB" ? required() : ""}
                />

            </FormTab>

        </TabbedForm>
    </Edit>
    )
};

PurchaseEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PurchaseEdit;
