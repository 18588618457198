/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class RoleField extends Component {
    constructor(props) {
	    super(props);
	    this.state = {role: ""};
	 }

    componentDidMount() {
    	const { record } = this.props;
        axios.get(loobackApiUrl+'/AppUsers/getRole?userId='+record.id).then(function (response) {
            this.setState({
	        	role: response.data.role
	      	});
        }.bind(this));
    }

    render() {
        return (
            <p>{this.state.role}</p>
        );
    }
}

export default RoleField;
