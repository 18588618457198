/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SaveButton,
    Toolbar,
    TabbedForm,
    FormTab,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    LongTextInput,
    NumberInput,
    NullableBooleanInput,
    DateInput,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    required,
} from 'react-admin';
import CurrentBoxLabelField from './CurrentBoxLabelField';
import CurrentInnerPageField from './CurrentInnerPageField';
import CurrentImageListField from './CurrentImageListField';
import { dateFormatter, dateParser } from '../lib/date';

const StockOutRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}

const StockOutRequestEdit = function({ permissions, ...props }) {

 return (
    <Edit {...props}>
        <TabbedForm toolbar={<StockOutRequestEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockoutrequests.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    label="resources.stockoutrequests.fields.product" 
                    reference="products" 
                    perPage={100} 
                    validate={required()} 
                    filterToQuery={searchText => ({name: {"like": `%${searchText}%`, "options": "i"}})} 
                    filter={{"status": "active"}}
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'stl', name: 'STL' },
                ]} validate={required()} />
                <DateInput
                    source="stockOutDate"
                    format={dateFormatter} parse={dateParser}
                />
                <SelectInput source="shippingCompany" choices={[
                    { id: 'Fedex', name: 'Fedex' },
                    { id: 'UPS', name: 'UPS' },
                    { id: 'USPS', name: 'USPS' },
                ]} />
                <FileInput source="boxLabel" validate={required()}>
                    <FileField source="src" title="title" />
                </FileInput>
                <CurrentBoxLabelField label="resources.stockoutrequests.fields.current_boxLabel" />
                <ImageInput source="innerPage">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <CurrentInnerPageField label="resources.stockoutrequests.fields.current_innerPage" />
                <ImageInput source="images" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <CurrentImageListField label="resources.stockoutrequests.fields.current_images" />
                <TextInput
                    source="barcode"
                />
                <SelectInput source="speed" allowEmpty emptyValue="" choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]} />
                <DateInput
                    source="stockOutDeadline"
                    format={dateFormatter} parse={dateParser}
                />
                <SelectInput source="status" choices={[
                    { id: 'stocking_out', name: 'resources.stockoutrequests.tab.stocking_out' },
                    { id: 'handling', name: 'resources.stockoutrequests.tab.handling' },
                    { id: 'stocked_out', name: 'resources.stockoutrequests.tab.stocked_out' },
                    { id: 'cancelled', name: 'resources.stockoutrequests.tab.cancelled' },
                ]} validate={required()} disabled />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" perPage={100} filter={{ realm: "PM" }} filterToQuery={searchText => ({username: {"like": `%${searchText}%`, "options": "i"}})} sort={{ field: 'id', order: 'ASC' }} validate={required()} defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <AutocompleteInput optionText="username" />
                </ReferenceInput>
                {permissions === 'WM' && <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText="code" />
                </ReferenceInput>  }
                {permissions !== 'WM' && <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput> }
                <SelectInput source="country" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'US', name: 'US' },
                    { id: 'CA', name: 'CA' },
                    { id: 'MX', name: 'MX' },
                    { id: 'UK', name: 'UK' },
                    { id: 'DE', name: 'DE' },
                    { id: 'FR', name: 'FR' },
                    { id: 'IT', name: 'IT' },
                    { id: 'JP', name: 'JP' },
                    { id: 'AU', name: 'AU' },
                ]} />
                
                <TextInput
                    source="estimated_task_time"
                />
                <DateInput
                    source="confirmed_stockout_date"
                    format={dateFormatter} parse={dateParser}
                />
                <TextInput
                    source="tracking_number"
                />
                <TextInput
                    source="fba_id"
                />
                <NullableBooleanInput source="is_delivered" />
                <LongTextInput
                    source="note"
                />
            </FormTab>

        </TabbedForm>
    </Edit>
    );
}

StockOutRequestEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockOutRequestEdit;
