import React from 'react';
import { Show, EditButton, TextField, NumberField, DateField, ReferenceManyField, Datagrid, ReferenceField, Pagination, translate } from 'react-admin';
import compose from 'recompose/compose';
import PaymentEmbedded from '../purchases/PaymentEmbedded';
import NoteField from '../stock_out_requests/NoteField';

const PurchaseIdField = ({ record }) => (
    <span><a href={`/#/purchases/${record.id}`} style={{textDecoration: "none"}}>OR{record.id.toString().padStart(5, '0')}</a></span>
);

const PurchaseReferenceField = props => (
    <div>
        {props.record.purchaseId ?
            <ReferenceField
                source="purchaseId"
                reference="purchases"
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : ""
        }
    </div>
);

const PackageStockEmbedded = function({ permissions, ...props }) {
    
    return (
    /* Passing " " as title disables the custom title */
    <div>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.packages.record.purchase')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="purchases"
                        target="packageId"
                        pagination={<Pagination />}
                        perPage={5}
                    >
                        <Datagrid expand={<PaymentEmbedded permissions={permissions} />}>
                            <PurchaseIdField label="resources.purchases.fields.id" />
                            <DateField source="created" options={{timeZone: "UTC"}}/>
                            <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                            <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <TextField source="name" />
                            <NumberField source="qty" />
                            <TextField source="status" />
                            <NumberField source="unit_price" options={{ style: 'currency', currency: 'USD' }} />
                            <NumberField source="unit_price_cny" options={{ style: 'currency', currency: 'CNY' }} />
                            <NumberField source="total_price" options={{ style: 'currency', currency: 'USD' }} />
                            <NumberField source="total_price_cny" options={{ style: 'currency', currency: 'CNY' }} />
                            <NoteField />
                            <EditButton />
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.packagestockins.record')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="packagestockins"
                        target="packageId"
                        pagination={<Pagination />}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <ReferenceField source="packageId" reference="packages" label="resources.packagestockins.fields.package">
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="qty" />
                            <DateField source="stockInDate" options={{timeZone: "UTC"}}/>
                            <ReferenceField source="appUserId" reference="appusers" label="resources.packagestockins.fields.user">
                                <TextField source="username" />
                            </ReferenceField>
                            <PurchaseReferenceField label="resources.packagestockins.fields.purchase"/>
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.packagestockouts.record')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="packagestockouts"
                        target="packageId"
                        pagination={<Pagination />}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <ReferenceField source="packageId" reference="packages" label="resources.packagestockouts.fields.package">
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="qty" />
                            <DateField source="stockOutDate" options={{timeZone: "UTC"}}/>
                            <ReferenceField source="appUserId" reference="appusers" label="resources.packagestockouts.fields.user">
                                <TextField source="username" />
                            </ReferenceField>
                            <PurchaseReferenceField label="resources.packagestockouts.fields.purchase"/>
                        </Datagrid>
                </ReferenceManyField>
        </Show>
    </div>
    );
}

PackageStockEmbedded.propTypes = Show.propTypes;

const enhance = compose(
    translate
);

export default enhance(PackageStockEmbedded);




