/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class CopyButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let today = new Date();
        window.location = '#/stockoutrequests/create?product_id='+record.productId+'&qty='+record.qty+'&warehouse='+record.warehouse
        +'&shippingCompany='+record.shippingCompany+'&barcode='+record.barcode+'&speed='+record.speed
        +'&stockOutDeadline='+today.toString()+'&user_id='+record.appUserId+'&account_id='+record.accountId+'&country='+record.country
        +'&note='+ (record.note ? record.note : '');    
    }

    render() {
        return (
            <Button label="copy" onClick={this.handleClick} color="primary" variant="raised">Copy</Button>
        );
    }
}

CopyButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(CopyButton);