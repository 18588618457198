import React from 'react';
import ImagePopover from './ImagePopover';
import pure from 'recompose/pure';

const ProductImageField = function({ record = {}, size }) {
	if(record) {
		return (
		<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
	        <ImagePopover record={record} />
	        &nbsp;{record ? record.name : ""}
	    </div>
	    )
	} else {
		return ""
	}
};

const PureProductImageField = pure(ProductImageField);

PureProductImageField.defaultProps = {
    source: 'name',
    label: 'resources.products.fields.name',
};

export default PureProductImageField;