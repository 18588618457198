/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    DateInput,
    TextInput,
    LongTextInput,
    SelectInput,
    FormDataConsumer,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    required,
} from 'react-admin';

import PriceInputs from './PriceInputs';
import autoUpdatePrice from './autoUpdatePrice';

const PurchaseCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}



const PurchaseCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
    const packageId = urlParams.get('package_id') ? parseInt(urlParams.get('package_id')) : '';
    const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";
    const role = JSON.parse(localStorage.getItem('lbrole')).value;

    return (
    <Create {...props}>
        <TabbedForm toolbar={<PurchaseCreateToolbar permissions={permissions} />} onChange={autoUpdatePrice}>
            <FormTab label="resources.purchases.form.summary" path="">

                { productId && 
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.purchases.fields.product" 
                    validate={required()} 
                    defaultValue={productId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                { packageId && 
                <ReferenceInput 
                    source="packageId" 
                    reference="packages" 
                    perPage={100} 
                    label="resources.purchases.fields.package" 
                    validate={required()} 
                    defaultValue={packageId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />

                <TextInput
                    source="fnsku"
                    defaultValue=""
                />

                <PriceInputs />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

                <ReferenceInput source="accountId" reference="accounts" label="resources.purchases.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>

                <SelectInput source="transport" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'sea', name: 'resources.purchases.transport.sea' },
                    { id: 'air', name: 'resources.purchases.transport.air' },
                    { id: 'sea_air', name: 'resources.purchases.transport.sea_air' },
                    { id: 'factory', name: 'resources.purchases.transport.factory' },
                ]}
                />

                <DateInput
                    source="production_finish_date"
                    validate={role == "PB" ? required() : ""}
                />

            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default PurchaseCreate;
