/* eslint react/jsx-key: off */
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    TextInput,
    SelectInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    DateField,
    TextField,
    UrlField,
    ReferenceField,
    Pagination,
    CardActions,
    CreateButton,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import MobileGrid from './MobileGrid';

import MoveToPendingButton from './MoveToPendingButton';
import MoveToPreparationButton from './MoveToPreparationButton';
import MoveToPreapprovedButton from './MoveToPreapprovedButton';
import MoveToApprovedButton from './MoveToApprovedButton';
import MoveToPaidButton from './MoveToPaidButton';
import MoveToCanceledButton from './MoveToCanceledButton';
import MoveToSpecialButton from './MoveToSpecialButton';
import PaymentDatagrid from '../lib/PaymentDatagrid';
import TransportField from '../purchases/TransportField';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'payments-'+n)
}

const AmountField = ({ record }) => (
    <span>¥{new Intl.NumberFormat().format(record.amount_cny)} (${new Intl.NumberFormat().format(record.amount)})</span>
);

const JianbingguoziField = ({ record }) => (
    <span><a href={`${record.jianbingguozi}`} style={{textDecoration: "none"}} target="_blank">{record.jianbingguozi ? "Purchase Proof" : ""}</a></span>
);

const PercentageField = ({ record }) => (
    <span>{record.percentage * 100}%</span>
)

const PaymentIdField = ({ record }) => (
    <span><a href={`/#/payments/${record.id}`} style={{textDecoration: "none"}}>{record.purchaseId ? 'OR' + record.purchaseId.toString().padStart(4, '0') + 'P' + record.count : ''}</a></span>
);

const PaymentTypeField = ({ record }) => (
    <span>
    {
    record.payment_type === "full_payment" ?
    "Full Payment(全款)" :
    record.payment_type === "deposit_payment" ?
    "Deposit Payment(首款)" :
    record.payment_type === "balance_payment" ?
    "Balance Payment(尾款)" : ""
    }
    </span>
)

const PurchaseReferenceField = props => (
    <div>
        {props.record.purchaseId ?
            <ReferenceField
                source="purchaseId"
                reference="purchases"
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : "Special Payment"
        }
    </div>
);

const PurchaseReferenceTypeField = props => (
    <div>
        {props.record.purchaseId ?
            <ReferenceField
                source="purchaseId"
                reference="purchases"
                {...props}
            >
                <PurchaseTypeField />
            </ReferenceField> : ""
        }
    </div>
);

const PurchaseTypeField = props => (
    <div>
        {props.record.productId ?
            "Product" : "Package"
        }
    </div>
);

const styles = {

}
const get_week_day = () => {
    let d = new Date();
    return d.getDay();
}

const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";
console.log(userId);
const is_team_leader = userId === 2 || userId === 5 || userId === 26 || userId === 18 || userId === 7 || userId === 39 || userId === 8 || userId === 46;

const week_day = JSON.parse(localStorage.getItem('week_day')) ? JSON.parse(localStorage.getItem('week_day')).value : get_week_day();
console.log(week_day);
let is_thursday_2_sunday = 1;
console.log(is_thursday_2_sunday);

const PaymentBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>
        { (permissions === "Admin" || (is_team_leader && is_thursday_2_sunday) ) &&
        <MoveToPendingButton {...props} />
        }
        { (permissions === "Admin" || (is_team_leader && is_thursday_2_sunday) ) &&
        <MoveToPreparationButton {...props} />
        }
        { (permissions === "Admin" || (is_team_leader && is_thursday_2_sunday) ) &&
        <MoveToPreapprovedButton {...props} />
        }
        { (permissions === "Admin") &&
        <MoveToApprovedButton {...props} />
        }
        { (permissions === "Admin" || permissions === "FM" || userId === 26) &&
        <MoveToPaidButton {...props} />
        }
        { (permissions === "Admin" || permissions === "PM" || userId === 26) &&
        <MoveToCanceledButton {...props} />
        }
        { (permissions === "Admin" || (is_team_leader && is_thursday_2_sunday) ) &&
        <MoveToSpecialButton {...props} />
        }
        
    </Fragment>
);

const PaymentActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <CardActions>
        { permissions === "Admin" || permissions === "PM" ?
            <CreateButton basePath={basePath} /> : ""
        }
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const PaymentPagination = props => <Pagination rowsPerPageOptions={[50, 100, 200, 300]} {...props} />

const PaymentFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
          label="resources.purchases.fields.id"
          type="search"
          source="pid"
          margin="normal"
          alwaysOn
        />
        <SelectInput source="payment_type" alwaysOn choices={[
                    { id: 'full_payment', name: 'Full Payment(全款)' },
                    { id: 'deposit_payment', name: 'Deposit Payment(首款)' },
                    { id: 'balance_payment', name: 'Balance Payment(尾款)' },
                ]}
                resettable
        />
        <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" filter={{ realm: "PM" }} sort={{ field: 'username', order: 'ASC' }} alwaysOn perPage={100}>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        <DateInput source="payment_date_start" alwaysOn options={{timeZone: "UTC"}} />
        <DateInput source="payment_date_end" alwaysOn options={{timeZone: "UTC"}} />
        <Button onClick={() => props.setFilters({
          status: props.filterValues.status,
        })} alwaysOn>Clear filters</Button>
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'admin'
        ? Promise.resolve('')
        : Promise.resolve('');
});

class TabbedDatagrid extends React.Component {

    state = { pending: [], preparation: [], preapproved: [], approved: [], paid: [], canceled: [], special: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    { ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="pending"
                            label={translate(`resources.payments.tab.pending`)}
                            value="pending"
                        />
                    }
                    { ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="preparation"
                            label={translate(`resources.payments.tab.preparation`)}
                            value="preparation"
                        />
                    }
                    { ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="preapproved"
                            label={translate(`resources.payments.tab.preapproved`)}
                            value="preapproved"
                        />
                    }
                    { ['Admin', 'PM', 'PB', 'FM'].includes(permissions) &&
                        <Tab
                            key="approved"
                            label={translate(`resources.payments.tab.approved`)}
                            value="approved"
                        />
                    }
                    { ['Admin', 'PM', 'PB', 'FM'].includes(permissions) &&
                        <Tab
                            key="paid"
                            label={translate(`resources.payments.tab.paid`)}
                            value="paid"
                        />
                    }
                    { ['Admin', 'PM', 'PB'].includes(permissions) &&
                        <Tab
                            key="canceled"
                            label={translate(`resources.payments.tab.canceled`)}
                            value="canceled"
                        />
                    }
                    { ['Admin', 'PM', 'PB'].includes(permissions) &&
                        <Tab
                            key="special"
                            label={translate(`resources.payments.tab.special`)}
                            value="special"
                        />
                    }
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                            permissions={permissions}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'pending' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.pending} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField label="resources.payments.fields.id" />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>
                                    
                                    
                                    
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" /> 
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
  
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                    
                                    <TextField source="note" />
                                    
                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                            {filterValues.status === 'preparation' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.preparation} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                        
                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                            {filterValues.status === 'preapproved' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.preapproved} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
                                    
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>

                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                            {filterValues.status === 'approved' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.approved} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                    
                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                            {filterValues.status === 'paid' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.paid} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                    
                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                            {filterValues.status === 'canceled' && (
                                <Datagrid {...props} 
                                    ids={this.state.canceled} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>

                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                    
                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </Datagrid>
                            )}
                            {filterValues.status === 'special' && (
                                <PaymentDatagrid {...props} 
                                    ids={this.state.special} 
                                    rowClick={rowClick(permissions)}
                                >
                                    <PaymentIdField />
                                    <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                                    <PurchaseReferenceField label="resources.payments.fields.purchase"/>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.qty" allowEmpty emptyValue="" sortable={false}>
                                        <TextField source="qty" />
                                    </ReferenceField>

                                    <PurchaseReferenceTypeField label="resources.payments.fields.purchase_type"/>
                                    
                                    <PercentageField source="percentage" label="resources.payments.fields.percentage" />
                                    <AmountField source="amount" label="resources.payments.fields.amount" />
                                    <PaymentTypeField source="payment_type" label="resources.payments.fields.payment_type" />
                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.transport" sortable={false}>
                                        <TransportField />
                                    </ReferenceField>
                                    
                                    <TextField source="note" />

                                    <JianbingguoziField source="jianbingguozi" label="resources.payments.fields.jianbingguozi"/>

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.payments.fields.user" sortable={false}>
                                        <TextField source="username" />
                                    </ReferenceField>

                                    <ReferenceField source="purchaseId" reference="purchases" label="resources.purchases.fields.production_finish_date" sortable={false}>
                                        <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                                    </ReferenceField>

                                    <TextField source="note2" />

                                    <EditButton />
                                </PaymentDatagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );

    }
}

const PaymentList = ({ classes, permissions, ...props }) => { 

let role = JSON.parse(localStorage.getItem('lbrole')).value;

return (
    role === "FM" ?
    <List
        {...props}
        filterDefaultValues={{ status: 'approved' }}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={300}
        pagination={<PaymentPagination />}
        filters={<PaymentFilter permissions={permissions} />}
        actions={<PaymentActions permissions={permissions} />}
        bulkActionButtons={<PaymentBulkActionButtons permissions={permissions} />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
    :
    <List
        {...props}
        filterDefaultValues={{ status: 'pending' }}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={300}
        pagination={<PaymentPagination />}
        filters={<PaymentFilter permissions={permissions} />}
        actions={<PaymentActions permissions={permissions} />}
        bulkActionButtons={<PaymentBulkActionButtons permissions={permissions} />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
)};


export default withStyles(styles)(PaymentList);
