/* eslint react/jsx-key: off */
import React from 'react';
import { ReferenceField } from 'react-admin';
import ProductImageField from '../products/ProductImageField';

const ProductReferenceField = props => (
    <div>
        {props.record.productId ?
            <ReferenceField
                label="Product"
                source="productId"
                reference="products"
                {...props}
            >
                <ProductImageField />
            </ReferenceField> : ""
        }
    </div>
);

ProductReferenceField.defaultProps = {
    source: 'productId',
    addLabel: true,
};

export default ProductReferenceField;
