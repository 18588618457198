import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class PaymentButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/payments/create?purchase_id='+record.id+'&total='+record.total_price+'&total_cny='+record.total_price_cny;  
    }

    render() {
        return (
        <div>
            <Button label="payment" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.purchases.actions.payment')}</Button>
        </div>
        );
    }
}

PaymentButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(PaymentButton);