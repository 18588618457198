import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const RequestedAside = ({ purchaseId, productId, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
            <div style={{margin: '0 0 0 1em'}}>
                {productId && <ProductInfo productId={productId} basePath={basePath} translate={translate} />}
            </div>

            <div style={{margin: '1em 0 0 1em'}}>
                {purchaseId && <PurchaseInfo purchaseId={purchaseId} basePath={basePath} translate={translate} />}
            </div>

            <div style={{margin: '1em 0 0 1em'}}>
                {purchaseId && <StockInRequestHistoryInfo purchaseId={purchaseId} basePath={basePath} translate={translate} />}
            </div>

        </div>
    );
};

RequestedAside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const imgStyle = {
  maxHeight: '5rem',
  position: 'relative',
  margin: '0.5rem',
}

const ProductInfo = ({ productId, basePath, translate }) => (
    <Query type="GET_ONE" resource="products" payload={{id: productId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/products/${data.id}` : "";
            return (
                <Card>
                    <CardHeader
                            title={translate('resources.products.form.summary')}
                    />
                    <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.image')}</TableCell>
                              <TableCell width="75%"><a href={redirect}><img src={data.image.replace("s3.amazonaws.com", "s3.us-east-1.amazonaws.com")} style={imgStyle} alt={data.name} /></a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.name')}</TableCell>
                              <TableCell width="75%"><a href={redirect}>{data.name}</a> ({data.boxmark_name})</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.qty')}</TableCell>
                              <TableCell width="75%">{data.qty}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.shelf')}</TableCell>
                              <TableCell width="75%">{data.shelf}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)


const PurchaseInfo = ({ purchaseId, basePath, translate }) => (
    <Query type="GET_ONE" resource="purchases" payload={{id: purchaseId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                            title={translate('resources.purchases.form.summary')}
                    />
                    <CardContent>
                        <Table aria-label="leftbox table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qty')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.purchase')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.record.purchase_boxes')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInBox')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInTrailBox')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.weightPerBox')}</TableCell>
                              </TableRow>
                            </TableHead>
                          <TableBody>
                              <TableRow>
                                <TableCell style={{padding: 0}}>{data.qty}</TableCell>
                                <TableCell style={{padding: 0}}><a href={'/#/purchases/' + data.id}>OR{data.id.toString().padStart(4, '0')}</a></TableCell>
                                <TableCell style={{padding: 0}}>{data.boxes}</TableCell>
                                <TableCell style={{padding: 0}}>{data.qtyInBox}</TableCell>
                                <TableCell style={{padding: 0}}>{data.qtyInTrailBox}</TableCell>
                                <TableCell style={{padding: 0}}>{data.weightPerBox}</TableCell>
                              </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)

const StockInRequestHistoryInfo = ({ purchaseId, basePath, translate }) => (

        <Query type="GET_LIST" resource="stockinrequests" payload={{
           pagination: { page: 1, perPage: 7000 },
           sort: { field: 'id', order: 'ASC' },
           filter: { purchaseId: purchaseId }
        }}>
              {({ data, total, loading, error }) => {
                  if (data === null) { return <p></p>; }
                  if (error) { return <p>ERROR</p>; }
                  return (
                    <Card>
                      <CardHeader
                            title={translate('resources.stockinrequests.form.history')}
                        />
                      <CardContent>  
                          <Table aria-label="leftbox table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{padding: 0}}>ID</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.record.batch_boxes')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyBatch')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyStockedIn')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyPending')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.estArrivalDate')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.waybills')}</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                            {data.map((row) => (
                                <TableRow key={row}>
                                  <TableCell style={{padding: 0}}><a href={`/#/stockinrequests/${row.id}`}>SIR{row.id.toString()}</a></TableCell>
                                  <TableCell style={{padding: 0}}>{row.boxes_end - row.boxes_start + 1} Boxes : {row.boxes_start} - {row.boxes_end}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.qtyBatch}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.qtyStockedIn}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.qtyPending}</TableCell>
                                  <TableCell style={{padding: 0}}>{row.estArrivalDate ? new Date(Date.parse(row.estArrivalDate)).toISOString().split('T')[0] : ""}</TableCell>
                                  <TableCell style={{padding: 0}}><WaybillList waybills={row.waybills} /></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      </CardContent>
                    </Card>
                  );
              }}
        </Query>
      
);

const WaybillList = ({waybills}) => {
  if(waybills === null || waybills.length === 0) {
    return ""
  }
  const listItems = waybills.map((w) =>
    <li>{ w.waybill + '-' + w.shippingCompany }</li>
  );
  return (
    <ul>{listItems}</ul>
  );
}

const enhance = compose(
    translate
);

export default enhance(RequestedAside);