import React from 'react';
import compose from 'recompose/compose';
import { Show, EditButton, TextField, ReferenceManyField, Datagrid, DateField, ReferenceField, Pagination, translate } from 'react-admin';

const StockInEmbedded = ({ permissions, ...props }) => (
    /* Passing " " as title disables the custom title */
    <div>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.stockinrequests.record.stockins')}:</h4>
        <Show {...props}>
            <ReferenceManyField
                    reference="stockins"
                    target="stockInRequestId"
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <ReferenceField source="appUserId" reference="appusers" label="resources.stockins.fields.user" >
                            <TextField source="username" />
                        </ReferenceField>
                        <TextField source="qty" />
                        <TextField source="box" />
                        <TextField source="warehouse" />
                        <DateField source="stockInDate" options={{timeZone: "UTC"}} />
                        {permissions === 'WM' ? <EditButton /> : "" }
                    </Datagrid>
            </ReferenceManyField>
        </Show>
    </div>
);

StockInEmbedded.propTypes = Show.propTypes;

const enhance = compose(
    translate
);

export default enhance(StockInEmbedded);




