import React from 'react';

const CurrentTransparencyField = function({ record = {} }) { 
	
	const transparencies = typeof record.transparency === "string" ? record.transparency.split(",") : '';
	const items = [];

	for (const value of transparencies) {
	    items.push(<li><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
	}

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    	<ul style={{listStyle: "none", padding: 0}}>
    		{items}
    	</ul>
    </div>
    )
};

CurrentTransparencyField.defaultProps = {
    source: 'transparency',
    addLabel: false,
};

export default CurrentTransparencyField;