/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, BooleanField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved

const PurchaseShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="status" />
            <TextField source="qty" />
            <TextField source="unit_price" />
            <TextField source="unit_price_cny" />
            <TextField source="total_price" />
            <TextField source="total_price_cny" />
            <TextField source="boxes" />
            <TextField source="qtyInBox" />
            <TextField source="qtyInTrailBox" />

            <ReferenceField label="Product" source="productId" reference="products" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Package" source="packageId" reference="packages" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
            <ReferenceField label="Account" source="accountId" reference="accounts" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <BooleanField source="neiye" />
        </SimpleShowLayout>
    </Show>
);

PurchaseShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PurchaseShow;
