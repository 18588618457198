/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    FormTab,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    TabbedForm,
    TextInput,
    LongTextInput,
    NumberInput,
    required,
} from 'react-admin';

import PackageTitle from './PackageTitle';

const PackageEdit = ({ permissions, ...props }) => (
    <Edit title={<PackageTitle />} {...props}>
        <TabbedForm>
            <FormTab label="resources.packages.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.packages.fields.product" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>                
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qtyInBox"
                />
                <NumberInput
                    source="box_weight"
                />
                <NumberInput
                    source="product_ratio"
                />
                <TextInput
                    source="box_size"
                    defaultValue=""
                />
                <LongTextInput
                    source="manufacture_info"
                    validate={required()}
                />
                <ReferenceInput source="appUserId" allowEmpty emptyValue=""  reference="appusers" label="resources.products.fields.user" perPage={100} filterToQuery={searchText => ({username: {"like": `%${searchText}%`, "options": "i"}})} sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="username" />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

PackageEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PackageEdit;
