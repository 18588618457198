import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PaymentButton from './PaymentButton';
import StockInRequestButton from './StockInRequestButton';

const Aside = ({ record, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
            <div style={{margin: '0 0 0 1em'}}>
                {record && record.productId && <ProductInfo record={record} basePath={basePath} translate={translate} />}
                {record && record.packageId && <PackageInfo record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && <PaymentList record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && <StockInRequestList record={record} basePath={basePath} translate={translate} />}
            </div>
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const imgStyle = {
  maxHeight: '5rem',
  position: 'relative',
  margin: '0.5rem',
}

const ProductInfo = ({ record, basePath, translate }) => (
    <Query type="GET_ONE" resource="products" payload={{id: record.productId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/products/${data.id}` : "";
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.products.form.summary')}
                    />
                    <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.image')}</TableCell>
                              <TableCell width="75%"><a href={redirect}><img src={data.image.replace("s3.amazonaws.com", "s3.us-east-1.amazonaws.com")} style={imgStyle} alt={data.name} /></a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.name')}</TableCell>
                              <TableCell width="75%"><a href={redirect}>{data.name}</a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.boxmark_name')}</TableCell>
                              <TableCell width="75%">{data.boxmark_name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.brand')}</TableCell>
                              <TableCell width="75%">{data.brand}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.qty')}</TableCell>
                              <TableCell width="75%">{data.qty}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.qtyInBox')}</TableCell>
                              <TableCell width="75%">{data.qtyInBox ? data.qtyInBox + "/" + translate('resources.products.form.box') : ''}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.box_weight')}</TableCell>
                              <TableCell width="75%">{data.box_weight}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.box_size')}</TableCell>
                              <TableCell width="75%">{data.box_size}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.shelf')}</TableCell>
                              <TableCell width="75%">{data.shelf}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.country')}</TableCell>
                              <TableCell width="75%">{data.country}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)

const PackageInfo = ({ record, basePath, translate }) => (
    <Query type="GET_ONE" resource="packages" payload={{id: record.packageId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/packages/${data.id}` : "";
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.packages.form.summary')}
                    />
                    <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.packages.fields.name')}</TableCell>
                              <TableCell width="75%"><a href={redirect}>{data.name}</a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.packages.fields.qty')}</TableCell>
                              <TableCell width="75%">{data.qty}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.qtyInBox')}</TableCell>
                              <TableCell width="75%">{data.qtyInBox}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.box_weight')}</TableCell>
                              <TableCell width="75%">{data.box_weight}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.products.fields.box_size')}</TableCell>
                              <TableCell width="75%">{data.box_size}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)


const payload = (record) => ({
   pagination: { page: 1, perPage: 10 },
   sort: { field: 'id', order: 'ASC' },
   filter: { purchaseId: record.id }
});

const PaymentList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="payments" payload={payload(record)}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.purchases.record.payment')}
                    />
                    <CardContent>
                      <PaymentButton record={record}/>
                      <Table aria-label="payment table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.id')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.payment_date')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.percentage')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.amount')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.payment_type')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.status')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.note')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.note2')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{padding: 0}}><a href={`/#/payments/${row.id}`}>{row.purchaseId ? 'OR' + row.purchaseId.toString().padStart(5, '0') + 'P' + row.count : ''}</a></TableCell>
                              <TableCell style={{padding: 0}}>{new Date(row.payment_date).toISOString().split('T')[0]}</TableCell>
                              <TableCell style={{padding: 0}}>{row.percentage * 100}%</TableCell>
                              <TableCell style={{padding: 0}}>${row.amount} / ¥{row.amount_cny}</TableCell>
                              <TableCell style={{padding: 0}}>{row.payment_type}</TableCell>
                              <TableCell style={{padding: 0}}>{row.status}</TableCell>
                              <TableCell style={{padding: 0}}>{row.note}</TableCell>
                              <TableCell style={{padding: 0}}>{row.note2}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
);

const StockInRequestList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="stockinrequests" payload={payload(record)}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.purchases.record.stock_in_request')}
                    />
                    <CardContent>
                      <StockInRequestButton record={record}/>
                      <Table aria-label="stock_in_request table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{padding: 0}}>ID</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.status')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qty')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyBatch')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyStockedIn')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyPending')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.boxes_start')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.boxes_end')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.boxes')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInBox')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.weightPerBox')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInTrailBox')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{padding: 0}}><a href={`/#/stockinrequests/${row.id}`}>{row.id}</a></TableCell>
                              <TableCell style={{padding: 0}}>{row.status}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyBatch}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyBatch - row.qtyPending}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyPending}</TableCell>
                              <TableCell style={{padding: 0}}>{row.boxes_start}</TableCell>
                              <TableCell style={{padding: 0}}>{row.boxes_end}</TableCell>
                              <TableCell style={{padding: 0}}>{row.boxes}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyInBox}</TableCell>
                              <TableCell style={{padding: 0}}>{row.weightPerBox}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyInTrailBox}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
);

const enhance = compose(
    translate
);

export default enhance(Aside);