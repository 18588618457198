import React from 'react';
import { Show, EditButton, TextField, NumberField, ReferenceManyField, Datagrid, DateField, ReferenceField, Pagination, translate } from 'react-admin';
import compose from 'recompose/compose';
import StockInStatusField from '../stock_in_requests/StatusField';
import StockInEmbedded from '../stock_in_requests/StockInEmbedded';
import StockInBoxButton from '../stock_in_requests/StockInBoxButton';
import StockOutEmbedded from '../stock_out_requests/StockOutEmbedded';
import StockOutButton from '../stock_out_requests/StockOutButton';
import StockOutStatusField from '../stock_out_requests/StatusField';
import DownloadBoxLabelField from '../stock_out_requests/DownloadBoxLabelField';
import NoteField from '../stock_out_requests/NoteField';
import QtyInBoxField from './QtyInBoxField';
import PaymentEmbedded from '../purchases/PaymentEmbedded';

const PurchaseIdField = ({ record }) => (
    <span><a href={`/#/purchases/${record.id}`} style={{textDecoration: "none"}}>OR{record.id.toString().padStart(5, '0')}</a></span>
);

const ProductRequestEmbedded = function({ permissions, ...props }) {
    
    return (
    /* Passing " " as title disables the custom title */
    <div>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.packages.record.purchase')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="purchases"
                        target="productId"
                        pagination={<Pagination />}
                        perPage={5}
                    >
                        <Datagrid expand={<PaymentEmbedded permissions={permissions} />}>
                            <PurchaseIdField label="resources.purchases.fields.id" />
                            <DateField source="created" options={{timeZone: "UTC"}}/>
                            <DateField source="production_finish_date" options={{timeZone: "UTC"}}/>
                            <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <TextField source="name" />
                            <NumberField source="qty" />
                            <TextField source="status" />
                            <NumberField source="unit_price" options={{ style: 'currency', currency: 'USD' }} />
                            <NumberField source="unit_price_cny" options={{ style: 'currency', currency: 'CNY' }} />
                            <NumberField source="total_price" options={{ style: 'currency', currency: 'USD' }} />
                            <NumberField source="total_price_cny" options={{ style: 'currency', currency: 'CNY' }} />
                            <NoteField />
                            <EditButton />
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.products.fields.package')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="packages"
                        target="productId"
                        pagination={<Pagination />}
                        perPage={5}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.packages.fields.user">
                                <TextField source="username" />
                            </ReferenceField>
                            <TextField source="name" />
                            <NumberField source="qty" />
                            <QtyInBoxField />
                            <NumberField source="box_weight" />
                            <NumberField source="box_size" />
                            { permissions === "Admin" || permissions === "PM" || permissions === "PB" || permissions === "WM" ? <EditButton /> : "" }
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.products.record.stock_in_request')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="stockinrequests"
                        target="productId"
                        pagination={<Pagination />}
                        perPage={5}
                    >
                        <Datagrid expand={<StockInEmbedded permissions={permissions} />}>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <StockInStatusField />
                            <NumberField source="boxes" />
                            <NumberField source="qtyInBox" />
                            <NumberField source="weightPerBox" />
                            <NumberField source="qtyInTrailBox" />
                            <NumberField source="qty" />
                            <NumberField source="qtyBatch" />
                            <NumberField source="qtyStockedIn" />
                            <NumberField source="qtyPending" />
                            <TextField source="warehouse" />
                            <TextField source="waybill" />
                            <DateField source="estArrivalDate" options={{timeZone: "UTC"}}/>
                            <TextField source="shippingCompany" />
                            <EditButton />
                            {permissions === 'WM' ? <StockInBoxButton /> : "" }
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.products.record.stock_out_request')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="stockoutrequests"
                        target="productId"
                        pagination={<Pagination />}
                        perPage={5}
                    >
                        <Datagrid expand={<StockOutEmbedded permissions={permissions} />}>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <StockOutStatusField />
                            <NumberField source="qty" />
                            <TextField source="warehouse" />
                            <DateField source="stockOutDate" options={{timeZone: "UTC"}}/>
                            <TextField source="shippingCompany" />
                            <TextField source="barcode" />
                            <DownloadBoxLabelField />
                            <TextField source="innerPage" />
                            <TextField source="speed" />
                            <NoteField />
                            <EditButton />
                            {permissions === 'WM' ? <StockOutButton /> : "" }
                        </Datagrid>
                </ReferenceManyField>
        </Show>
    </div>
    );
}

ProductRequestEmbedded.propTypes = Show.propTypes;

const enhance = compose(
    translate
);

export default enhance(ProductRequestEmbedded);




