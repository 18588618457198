import React, { Component }  from 'react';
import {showNotification, UPDATE, withDataProvider} from 'react-admin';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class ConfirmedStockoutDateEditableField extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isEditing : false,
            confirmed_stockout_date: this.props.record.confirmed_stockout_date ? new Date(Date.parse(this.props.record.confirmed_stockout_date)) : ""
        }
    }

    setEditing = (isEditing) => {
        this.setState({
            isEditing: isEditing
        })
    }

    updateConfirmedStockoutDate = (date) => {
        this.setState({
            isEditing: false,
            confirmed_stockout_date: date
        })

       const { dataProvider, dispatch, record } = this.props;
       const updatedRecord = { ...record, confirmed_stockout_date: date.toISOString().split("T")[0] }; 
       dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
        .then(() => {
            dispatch(showNotification('resources.stockoutrequests.actions.confirmed_stockout_date_updated'));
        })
        .catch((e) => {
            dispatch(showNotification('resources.stockoutrequests.actions.confirmed_stockout_date_updated_error', 'warning'));
        });
    }

    formateDate = (date) => {
        let parts = date.toISOString().split("T")[0].split("-")
        return parts[1]+"/"+parts[2]+"/"+parts[0]
    }

    render() {
        return (
            <div>{this.state.isEditing ? (
                <div>
                  <DatePicker selected={this.state.confirmed_stockout_date} onChange={(date:Date) => this.updateConfirmedStockoutDate(date)} />
                </div>
              ) : (
                <div
                  onClick={() => this.setEditing(true)}
                >
                  <span>
                    {this.state.confirmed_stockout_date ? this.formateDate(this.state.confirmed_stockout_date) : "" || "Confirmed Stockout Date"}
                  </span>
                </div>
              )}
            </div>
        )
    }
};

ConfirmedStockoutDateEditableField.defaultProps = {
    source: 'confirmed_stockout_date',
    addLabel: true,
};

export default withDataProvider(ConfirmedStockoutDateEditableField);