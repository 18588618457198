import React from 'react';
import {translate} from 'react-admin';

const statusStyle = {
  minWidth: '40px',
}

const WeightPerBoxField = translate(({ record, translate }) => (
    <span style={statusStyle}>
        {
        	record.weightPerBox ? (record.weightPerBox*2.20462).toFixed(2) + "lb" : ""
        }
    </span>
));

WeightPerBoxField.defaultProps = {
    source: 'weightPerBox',
    addLabel: true,
};

export default WeightPerBoxField;