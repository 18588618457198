import React, { Component } from 'react';
import compose from 'recompose/compose';
import InputAdornment from '@material-ui/core/InputAdornment';
import {TextInput, NumberInput, required, translate} from 'react-admin';

class AmountInputsEdit extends Component {

    render() {
        return (
            <div>
            <NumberInput id="percentage" label={this.props.translate('resources.payments.fields.percentage')} source="percentage" validate={required()} className="FormInput-input-251" margin="normal"
                format={v => v*100} parse={v => v/100}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
            <br />
            <TextInput id="amount" label={this.props.translate('resources.payments.fields.amount')} source="amount" style={{marginRight: 20}} validate={required()} margin="normal"
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
            <TextInput id="amount_cny" label={this.props.translate('resources.payments.fields.amount_cny')} source="amount_cny" validate={required()} margin="normal"
                InputProps={{
                    startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                }}
            />
            </div>
        );
    }
}

const enhance = compose(
    translate
);

export default enhance(AmountInputsEdit);