/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField } from 'react-admin'; // eslint-disable-line import/no-unresolved


const AccountShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="code" />
            <TextField source="company" />
            <TextField source="address" />
            <TextField source="city" />
            <TextField source="state" />
            <TextField source="country" />
            <TextField source="zipcode" />
        </SimpleShowLayout>
    </Show>
);

AccountShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default AccountShow;
