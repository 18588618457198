/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class StockInAllButton extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false, done: false};
     }

    handleClick = (event) => {
        event.stopPropagation();
        if(this.state.loading === false && this.state.done === false) {
            this.setState({
                loading: true
            });
            const { record } = this.props;
            axios.get(loobackApiUrl+'/StockIns/stockInAll?stockInRequestId='+record.id, {
              headers: {
                'Authorization': `${JSON.parse(localStorage.getItem('lbtoken')).value.id}`
              }
            }).then(function (response) {
                            if(response.data.success) {
                                this.setState({
                                    loading: false,
                                    done: true
                                });
                            }
                        }.bind(this))
        }

        // window.location = '#/stockins/create?request_id='+record.id+'&product_id='+record.productId+'&box=0&qty='+record.qtyPending+'&qtyInBox='+record.qtyInBox+'&qtyInTrailBox='+record.qtyInTrailBox+'&startbox='+record.boxes_start+'&endbox='+record.boxes_end;    
    }

    render() {
        return (
            <Button label="stock_in" onClick={this.handleClick} color="primary" variant="raised">{this.state.loading ? this.props.translate('resources.stockinrequests.actions.stocking_in_left') : this.state.done ? this.props.translate('resources.stockinrequests.actions.stocked_in_left') : this.props.translate('resources.stockinrequests.actions.stock_in_left')}</Button>
        );
    }
}

StockInAllButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInAllButton);