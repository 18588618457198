import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class PackageButton extends Component {
    
    constructor(props) {
        super(props);
        this.state = {count: 0};
    }

    componentDidMount() {
        const { record } = this.props;
        axios.get(loobackApiUrl + '/Products/' + record.id + '/packages/count').then(function (response) {
            this.setState({
                count: response.data.count
            });
        }.bind(this));
    }

    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/packages/create?product_id='+record.id;    
    }

    render() {
        return (
        <div>
            <Button label="create_package" onClick={this.handleClick} color="default" variant="raised">{this.props.translate('resources.products.actions.create_package')} ({this.state.count})</Button>
        </div>
        );
    }
}

PackageButton.propTypes = {
    record: PropTypes.object,
};

const enhance = compose(
    translate
);

export default enhance(PackageButton);