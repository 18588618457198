/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    LongTextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    TextInput,
    Toolbar,
    required,
} from 'react-admin';

import AmountInputs from './AmountInputs';
import autoUpdatePrice from './autoUpdatePrice';
import Aside from './Aside';

const PaymentCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const PaymentCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const purchase_id = urlParams.get('purchase_id') ? parseInt(urlParams.get('purchase_id')) : '';
    const total = urlParams.get('total') ? parseFloat(urlParams.get('total')) : '';
    const total_cny = urlParams.get('total_cny') ? parseFloat(urlParams.get('total_cny')) : '';

    return (
    <Create aside={ purchase_id && <Aside /> } {...props}>
        <TabbedForm toolbar={<PaymentCreateToolbar permissions={permissions} />} onChange={autoUpdatePrice(total, total_cny)}>
            <FormTab label="resources.payments.form.summary" path="">

                { purchase_id && 
                <ReferenceInput 
                    source="purchaseId" 
                    reference="purchases" 
                    perPage={100} 
                    label="resources.payments.fields.purchase" 
                    validate={required()} 
                    defaultValue={purchase_id}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                <AmountInputs purchaseId={purchase_id} total={total} total_cny={total_cny} />

                <DateInput
                    source="payment_date"
                    label="resources.payments.fields.payment_date"
                    defaultValue={new Date()}
                />

                <SelectInput source="payment_type" choices={[
                    { id: 'full_payment', name: 'Full Payment (全款)' },
                    { id: 'deposit_payment', name: 'Deposit Payment (首款)' },
                    { id: 'balance_payment', name: 'Balance Payment (尾款)' },
                ]} validate={required()} />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" filter={{ realm: "PM" }} sort={{ field: 'username', order: 'ASC' }} defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

                <TextInput source="jianbingguozi" label="resources.payments.fields.jianbingguozi" validate={required()} />

                <LongTextInput source="shipment_plan" />

                <LongTextInput source="shipment_plan_review" />

                <LongTextInput
                    source="note"
                />
            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default PaymentCreate;
