import axios from 'axios';
import {loobackApiUrl} from '../config';
import moment from 'moment';

var change_field = "";

const autoUpdatePrice = function(event) {

  if(event.target){
    change_field = event.target.id;
  }

  if(change_field === "qty") {
    if(event.unit_price && event.unit_price_cny) {
      if(event.qty !== undefined) {
        event.total_price = (event.qty * event.unit_price).toFixed(2);
        event.total_price_cny = (event.qty * event.unit_price_cny).toFixed(2);   
      }
    }
  }

  if(change_field === "unit_price") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "USD", "target": "CNY"}}}}).then((res) => {

            if(res.status === 200) {
                event.unit_price_cny = (parseFloat(event.unit_price) * parseFloat(res.data.exchange_rate)).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.exchangeratesapi.io/v1/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'access_key': '249e0d10d68d475744cd98a28caa8e71',
                  'base': "USD",
                  'symbols': "CNY"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["CNY"];

                if(res.status === 200) {

                      axios.post(loobackApiUrl + '/Currencies', {
                        "date": moment().format("YYYY-MM-DD"),
                        "base": "USD",
                        "target": "CNY",
                        "exchange_rate": exchange_rate
                      }).then((res) => {
                          event.unit_price_cny = (parseFloat(event.unit_price) * parseFloat(exchange_rate)).toFixed(2);
                          if(event.qty !== undefined) {
                            event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2); 
                          }
                      })
         
                }

            })            
          }).then(() => {
            if(event.qty !== undefined) {
              event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
              event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2); 
            }
          });    
  }

  if(change_field === "unit_price_cny") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "CNY", "target": "USD"}}}}).then((res) => {
            if(res.status === 200) {
                event.unit_price = (parseFloat(event.unit_price_cny) * parseFloat(res.data.exchange_rate)).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.exchangeratesapi.io/v1/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'access_key': '249e0d10d68d475744cd98a28caa8e71',
                  'base': "CNY",
                  'symbols': "USD"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["USD"];

                if(res.status === 200) {

                    axios.post(loobackApiUrl + '/Currencies', {
                      "date": moment().format("YYYY-MM-DD"),
                      "base": "CNY",
                      "target": "USD",
                      "exchange_rate": exchange_rate
                    }).then((res) => {
                        event.unit_price = (parseFloat(event.unit_price_cny) * parseFloat(exchange_rate)).toFixed(2);
                        if(event.qty !== undefined) {
                          event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
                        }
                    })
                }

            })            
          }).then(() => {
            if(event.qty !== undefined) {
              event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2);
              event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
            }
          });

  }

  if(change_field === "air_qty") {
    console.log(event.air_qty)
    console.log(event.sea_qty)
    console.log(event.normal_sea_qty)
    if(event.productId !== undefined) {
      axios.get(loobackApiUrl + '/Products/' + event.productId).then((res) => {
        if(res.status === 200) {
          if(res.data.unit_weight) {
            event.air_price_cny = parseFloat(res.data.unit_weight) * event.air_qty * 0.076
            event.total_shipping_qty = ((event.air_qty === undefined || event.air_qty === null || event.air_qty === "") ? 0 : parseFloat(event.air_qty)) + ((event.sea_qty === undefined || event.sea_qty === null || event.sea_qty === "") ? 0 : parseFloat(event.sea_qty)) + ((event.normal_sea_qty === undefined || event.normal_sea_qty === null || event.normal_sea_qty === "") ? 0 : parseFloat(event.normal_sea_qty))
            event.total_shipping_price_cny = ((event.air_price_cny === undefined || event.air_price_cny === null || event.air_price_cny === "") ? 0 : parseFloat(event.air_price_cny)) + ((event.sea_price_cny === undefined || event.sea_price_cny === null || event.sea_price_cny === "") ? 0 : parseFloat(event.sea_price_cny)) + ((event.normal_sea_price_cny === undefined || event.normal_sea_price_cny === null || event.normal_sea_price_cny === "") ? 0 : parseFloat(event.normal_sea_price_cny))
          }
        }
      })
    }
  }

  if(change_field === "sea_qty") {
    if(event.productId !== undefined) {
      axios.get(loobackApiUrl + '/Products/' + event.productId).then((res) => {
        if(res.status === 200) {
          if(res.data.unit_weight) {
            event.sea_price_cny = parseFloat(res.data.unit_weight) * event.sea_qty * 0.031
            event.total_shipping_qty = ((event.air_qty === undefined || event.air_qty === null || event.air_qty === "") ? 0 : parseFloat(event.air_qty)) + ((event.sea_qty === undefined || event.sea_qty === null || event.sea_qty === "") ? 0 : parseFloat(event.sea_qty)) + ((event.normal_sea_qty === undefined || event.normal_sea_qty === null || event.normal_sea_qty === "") ? 0 : parseFloat(event.normal_sea_qty))
            event.total_shipping_price_cny = ((event.air_price_cny === undefined || event.air_price_cny === null || event.air_price_cny === "") ? 0 : parseFloat(event.air_price_cny)) + ((event.sea_price_cny === undefined || event.sea_price_cny === null || event.sea_price_cny === "") ? 0 : parseFloat(event.sea_price_cny)) + ((event.normal_sea_price_cny === undefined || event.normal_sea_price_cny === null || event.normal_sea_price_cny === "") ? 0 : parseFloat(event.normal_sea_price_cny))
          }
        }
      })
    }
  }

  if(change_field === "normal_sea_qty") {
    if(event.productId !== undefined) {
      axios.get(loobackApiUrl + '/Products/' + event.productId).then((res) => {
        if(res.status === 200) {
          if(res.data.unit_weight) {
            event.normal_sea_price_cny = parseFloat(res.data.unit_weight) * event.normal_sea_qty * 0.015
            event.total_shipping_qty = ((event.air_qty === undefined || event.air_qty === null || event.air_qty === "") ? 0 : parseFloat(event.air_qty)) + ((event.sea_qty === undefined || event.sea_qty === null || event.sea_qty === "") ? 0 : parseFloat(event.sea_qty)) + ((event.normal_sea_qty === undefined || event.normal_sea_qty === null || event.normal_sea_qty === "") ? 0 : parseFloat(event.normal_sea_qty))
            event.total_shipping_price_cny = ((event.air_price_cny === undefined || event.air_price_cny === null || event.air_price_cny === "") ? 0 : parseFloat(event.air_price_cny)) + ((event.sea_price_cny === undefined || event.sea_price_cny === null || event.sea_price_cny === "") ? 0 : parseFloat(event.sea_price_cny)) + ((event.normal_sea_price_cny === undefined || event.normal_sea_price_cny === null || event.normal_sea_price_cny === "") ? 0 : parseFloat(event.normal_sea_price_cny))
          }
        }
      })
    }
  }

}

export default autoUpdatePrice;