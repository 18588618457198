import PackageStockInIcon from '@material-ui/icons/List';
import PackageStockInList from './PackageStockInList';
import PackageStockInCreate from './PackageStockInCreate';
import PackageStockInEdit from './PackageStockInEdit';
import PackageStockInShow from './PackageStockInShow';

export default {
    list: PackageStockInList,
    create: PackageStockInCreate,
    edit: PackageStockInEdit,
    show: PackageStockInShow,
    icon: PackageStockInIcon,
};
