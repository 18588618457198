/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved
import StatusField from './StatusField';
import WeightPerBoxField from './WeightPerBoxField';

const StockInRequestShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Purchase" source="purchaseId" reference="purchases">
                <TextField source="name" />
            </ReferenceField>
            
            <ReferenceField label="Product" source="productId" reference="products">
                <TextField source="name" />
            </ReferenceField>
            
            <TextField source="qty" />
            <TextField source="qtyPending" />
            <TextField source="warehouse" />
            <TextField source="boxes" />
            <TextField source="boxes_start" />
            <TextField source="boxes_end" />
            <TextField source="qtyInBox" />
            <TextField source="qtyInTrailBox" />
            <TextField source="waybill" />
            <DateField source="estArrivalDate" options={{timeZone: "UTC"}} />
            <TextField source="shippingCompany" />
            <TextField source="shippingFee" />
            <StatusField />
            <WeightPerBoxField />

            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>



        </SimpleShowLayout>
    </Show>
);

StockInRequestShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockInRequestShow;
