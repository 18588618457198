import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Aside = ({ record, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
            <div style={{margin: '0 0 0 1em'}}>
                {record && record.purchaseId && <PurchaseInfo record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && record.purchaseId && <PaymentList record={record} basePath={basePath} translate={translate} />}
            </div>
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const PurchaseInfo = ({ record, basePath, translate }) => (
    <Query type="GET_ONE" resource="purchases" payload={{id: record.purchaseId ? record.purchaseId : record.purchase_id}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/purchases/${data.id}` : "";
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.purchases.form.summary')}
                    />
                    <CardContent>
                        <Table aria-label="payment table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.id')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.name')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.status')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.qty')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.unit_price')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.total_price')}</TableCell>
                                <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.production_finish_date')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                  <TableCell style={{padding: 0}}><a href={redirect}>{'OR' + data.id.toString().padStart(4, '0')}</a></TableCell>
                                  <TableCell style={{padding: 0}}><a href={redirect}>{data.name}</a></TableCell>
                                  <TableCell style={{padding: 0}}>{data.status}</TableCell>
                                  <TableCell style={{padding: 0}}>{data.qty}</TableCell>
                                  <TableCell style={{padding: 0}}>${data.unit_price} / ¥{data.unit_price_cny}</TableCell>
                                  <TableCell style={{padding: 0}}>${data.total_price} / ¥{data.total_price_cny}</TableCell>
                                  <TableCell style={{padding: 0}}>{new Date(data.production_finish_date).toISOString().split('T')[0]}</TableCell>
                                </TableRow>
                            </TableBody>
                            
                          </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>

)

const payload = (record) => ({
   pagination: { page: 1, perPage: 10 },
   sort: { field: 'id', order: 'ASC' },
   filter: { purchaseId: record.purchaseId ? record.purchaseId : record.purchase_id }
});

const PaymentList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="payments" payload={payload(record)}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.purchases.record.payment')}
                    />
                    <CardContent>
                      <Table aria-label="payment table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.id')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.payment_date')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.percentage')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.amount')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.status')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.payment_type')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.note')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.payments.fields.note2')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{padding: 0}}><a href={`/#/payments/${row.id}`}>{row.purchaseId ? 'OR' + row.purchaseId.toString().padStart(4, '0') + 'P' + row.count : ''}</a></TableCell>
                              <TableCell style={{padding: 0}}>{new Date(row.payment_date).toISOString().split('T')[0]}</TableCell>
                              <TableCell style={{padding: 0}}>{row.percentage * 100}%</TableCell>
                              <TableCell style={{padding: 0}}>${row.amount} / ¥{row.amount_cny}</TableCell>
                              <TableCell style={{padding: 0}}>{row.status}</TableCell>
                              <TableCell style={{padding: 0}}>{row.payment_type}</TableCell>
                              <TableCell style={{padding: 0}}>{row.note}</TableCell>
                              <TableCell style={{padding: 0}}>{row.note2}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
);

const enhance = compose(
    translate
);

export default enhance(Aside);