/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    DateInput,
    LongTextInput,
    NumberInput,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    Toolbar,
    required,
} from 'react-admin';

const StockOutRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
    </Toolbar>
);

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}

const StockOutRequestCreate = function({ permissions, ...props }) {
 const urlParams = new URLSearchParams(props.location.search);
 const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
 const userId = urlParams.get('user_id') ? parseInt(urlParams.get('user_id')) : JSON.parse(localStorage.getItem('lbtoken')).value.userId;
 const accountId = urlParams.get('account_id') ? parseInt(urlParams.get('account_id')) : '';

 return (
    <Create {...props}>
        <TabbedForm toolbar={<StockOutRequestEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockoutrequests.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    label="resources.stockoutrequests.fields.product" 
                    reference="products" 
                    perPage={100} 
                    validate={required()} 
                    defaultValue={productId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                    autoComplete="off"
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'stl', name: 'STL' },
                ]} defaultValue="stl" validate={required()} />
                <DateInput
                    source="stockOutDate"
                    defaultValue={new Date()}
                />
                
                <SelectInput source="shippingCompany" choices={[
                    { id: 'Fedex', name: 'Fedex' },
                    { id: 'UPS', name: 'UPS' },
                    { id: 'USPS', name: 'USPS' },
                ]} defaultValue="UPS" />
                <FileInput source="boxLabel" validate={required()}>
                    <FileField source="src" title="title" />
                </FileInput>
                <ImageInput source="innerPage" >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="images" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput
                    source="barcode"
                    autoComplete="off"
                />
                <SelectInput source="speed" allowEmpty emptyValue="" choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]} />
                <DateInput
                    source="stockOutDeadline"
                />
                <SelectInput source="status" choices={[
                    { id: 'stocking_out', name: 'resources.stockoutrequests.tab.stocking_out' },
                    { id: 'handling', name: 'resources.stockoutrequests.tab.handling' },
                    { id: 'stocked_out', name: 'resources.stockoutrequests.tab.stocked_out' },
                    { id: 'cancelled', name: 'resources.stockoutrequests.tab.cancelled' },
                ]} defaultValue="stocking_out" validate={required()} />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" perPage={100} filter={{ realm: "PM" }} filterToQuery={searchText => ({username: {"like": `%${searchText}%`, "options": "i"}})} sort={{ field: 'id', order: 'ASC' }}  validate={required()} defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <AutocompleteInput optionText="username" />
                </ReferenceInput>

                <ReferenceInput source="accountId" reference="accounts" defaultValue={accountId} label="resources.stockoutrequests.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}} sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>
                <SelectInput source="country" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'US', name: 'US' },
                    { id: 'CA', name: 'CA' },
                    { id: 'MX', name: 'MX' },
                    { id: 'UK', name: 'UK' },
                    { id: 'DE', name: 'DE' },
                    { id: 'FR', name: 'FR' },
                    { id: 'IT', name: 'IT' },
                    { id: 'JP', name: 'JP' },
                    { id: 'AU', name: 'AU' },
                ]} defaultValue="US" />
                <TextInput
                    source="fba_id"
                />


                <LongTextInput
                    source="note"
                />
            </FormTab>

        </TabbedForm>
    </Create>
)
};

export default StockOutRequestCreate;
