import axios from 'axios';
import {loobackApiUrl} from '../config';
import moment from 'moment';

var change_field = "";

const autoUpdatePrice = (total, total_cny) => (event) => {

  if(event.target){
    change_field = event.target.id;
  }

  if(change_field === "percentage") {
    if(event.amount && event.amount_cny) {
        event.amount = (event.percentage * total).toFixed(2);
        event.amount_cny = (event.percentage * total_cny).toFixed(2);   
    }
  }

  if(change_field === "amount") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "USD", "target": "CNY"}}}}).then((res) => {
            if(res.status === 200) {
                event.amount_cny = (event.amount * res.data.exchange_rate).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.exchangeratesapi.io/v1/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'access_key': '249e0d10d68d475744cd98a28caa8e71',
                  'base': "USD",
                  'symbols': "CNY"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["CNY"];

                if(res.status === 200) {

                    axios.post(loobackApiUrl + '/Currencies', {
                      "date": moment().format("YYYY-MM-DD"),
                      "base": "USD",
                      "target": "CNY",
                      "exchange_rate": exchange_rate
                    }).then((res) => {
                        event.amount_cny = (event.amount * res.data.exchange_rate).toFixed(2);
                    })
                }

            })            
          });    
  }

  if(change_field === "amount_cny") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "CNY", "target": "USD"}}}}).then((res) => {
            if(res.status === 200) {
                event.amount = (event.amount_cny * res.data.exchange_rate).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.exchangeratesapi.io/v1/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'access_key': '249e0d10d68d475744cd98a28caa8e71',
                  'base': "CNY",
                  'symbols': "USD"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["USD"];

                if(res.status === 200) {

                    axios.post(loobackApiUrl + '/Currencies', {
                      "date": moment().format("YYYY-MM-DD"),
                      "base": "CNY",
                      "target": "USD",
                      "exchange_rate": exchange_rate
                    }).then((res) => {
                        event.amount = (event.amount_cny * exchange_rate).toFixed(2);
                    })
                }

            })            
          });

  }

}

export default autoUpdatePrice;