import React, { Component }  from 'react';
import {showNotification, UPDATE, withDataProvider} from 'react-admin';

class IsDeliveredEditableField extends Component {

    handleClick = (event) => {

        const { dataProvider, dispatch, record } = this.props;
        const updatedRecord = { ...record, is_delivered: !record.is_delivered };
        dataProvider(UPDATE, 'stockoutrequests', { id: record.id, data: updatedRecord })
        .then(() => {
            dispatch(showNotification('Is Delivered updated'));
        })
        .catch((e) => {
            dispatch(showNotification('Error: Is Delivered can not be updated', 'warning'));
        });
           
    }

    render() {
        return (
            <input type="checkbox" checked={this.props.record.is_delivered} onChange={this.handleClick}/>
        )
    }
};

IsDeliveredEditableField.defaultProps = {
    source: 'is_delivered',
    addLabel: true,
};

export default withDataProvider(IsDeliveredEditableField);