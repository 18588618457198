/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    ReferenceInput,
    TextInput,
    DateInput,
    NumberInput,
    required,
} from 'react-admin';
import Aside from './Aside';
import withStyles from '@material-ui/core/styles/withStyles';
import { dateFormatter, dateParser } from '../lib/date';

const ProductField = function({ record }) { 
    return (
        record.productId ? 
                    <ReferenceInput 
                        source="productId" 
                        reference="products" 
                        perPage={100} 
                        label="resources.stockinrequests.fields.product" 
                        validate={required()} 
                        disabled
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    : ""
    );
}

const PurchaseField = function({ record }) { 
    return (
        record.purchaseId ? 
                <ReferenceInput 
                    source="purchaseId" 
                    reference="purchases" 
                    perPage={100} 
                    label="resources.stockinrequests.fields.purchase" 
                    validate={required()} 
                    disabled
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                : ""
    );
}

const styles = {
    boxes_start: { display: 'inline-block'},
    boxes_end: { display: 'inline-block', marginLeft: 32 },
};

const StockInRequestEdit = ({ classes,permissions, ...props }) => (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm>
            <FormTab label="resources.stockinrequests.form.summary" path="">

                <PurchaseField />
                <ProductField />
                
                <NumberInput
                    source="qtyBatch"
                    defaultValue=""
                    validate={required()}
                    label="resources.stockinrequests.record.batch_qty" 
                />
                <NumberInput
                    source="boxes_start"
                    validate={required()}
                    formClassName={classes.boxes_start}
                />
                <NumberInput
                    source="boxes_end"
                    validate={required()}
                    formClassName={classes.boxes_end}
                />
                <ArrayInput source="waybills">
                    <SimpleFormIterator>
                        <TextInput
                            source="waybill"
                            validate={required()}
                            label="resources.stockinrequests.fields.waybill" 
                        />
                        <SelectInput source="shippingCompany" choices={[
                            { id: 'DHL', name: 'DHL' },
                            { id: 'Fedex', name: 'Fedex' },
                            { id: 'UPS', name: 'UPS' },
                            { id: 'USPS', name: 'USPS' },
                        ]} defaultValue="DHL" validate={required()} 
                        label="resources.stockinrequests.fields.shippingCompany" 
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <DateInput
                    source="estArrivalDate"
                    label="resources.stockinrequests.fields.estArrivalDate"
                    format={dateFormatter} parse={dateParser}
                    validate={required()}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'stl', name: 'STL' },
                ]} validate={required()} />

                <SelectInput source="status" choices={[
                    { id: 'stocking_in', name: 'resources.stockinrequests.tab.stocking_in' },
                    { id: 'stocked_in', name: 'resources.stockinrequests.tab.stocked_in' },
                    { id: 'cancelled', name: 'resources.stockinrequests.tab.cancelled' },
                ]} />
                <ReferenceInput source="appUserId" reference="appusers" validate={required()} label="resources.stockinrequests.fields.user" >
                    <SelectInput optionText="username" />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

StockInRequestEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default withStyles(styles)(StockInRequestEdit);
